import ProjectCard from "./ProjectCard";
import { ProjectModel } from "../models/project.model";
import { useAppSelector } from "../../../app/hooks/useAppSelector";

export default function CardStack({
	stackTitle,
	showAddNewProject,
	setShowAddProjectModal,
	projects,
}: {
	stackTitle: string;
	showAddNewProject: boolean;
	setShowAddProjectModal: Function;
	projects: Array<ProjectModel>;
}) {
	const authState = useAppSelector((state) => state.auth);
	return (
		<div className='flex flex-col items-center w-3/12'>
			<h4 className='text-center text-white text-xl mb-8 font-bold'>
				{stackTitle}
			</h4>
			{/* Card */}
			{projects.map((project) => (
				<ProjectCard key={project.id} project={project} />
			))}

			{showAddNewProject && authState.user?.type === "EMPLOYEE" && (
				<button
					className='w-7/12 text-supplyBlue bg-white text-lg py-3'
					onClick={() => setShowAddProjectModal(true)}
				>
					<span className='font-bold'>+</span> Add New Project
				</button>
			)}
		</div>
	);
}
