import { useEffect, useState } from "react";
import ModalTemplate from "../../../app/components/ui/modals/ModalTemplate";
import FormSelectField from "../../../app/components/ui/forms/FormSelectField";
import { ProjectModel } from "../../projects/models/project.model";
import useDocuments from "../hooks/useDocuments";
import { toast } from "react-toastify";
import { getNotificationFromType } from "../../notifications/utils/type.util";
import useNotifications from "../../notifications/hooks/useNotifications";

interface Props {
	setShowModal: Function;
	project: ProjectModel;
	setProject: Function;
}

interface Row {
	id: string;
	associatedProject: string;
	section: string;
	customerEdit: boolean;
	notifyCustomer: string;
	files: FileList | null;
}

export default function DocumentModal({ setShowModal, project, setProject }: Props) {
	const [row, setRow] = useState<Row>({
		id: "",
		associatedProject: project.id,
		section: "",
		customerEdit: false,
		notifyCustomer: "",
		files: null,
	});

	console.log(project);

	const { isLoading, actionSuccessful, createNewRow } = useDocuments();
	const { createNotification } = useNotifications();

	const uploadDocumentsHandler = (files: FileList | null) => {
		if (files && files.length > 6) {
			toast.error("Please upload a maximum of 6 files");
			return;
		}
		if (files) {
			setRow({ ...row, files: files });
		}
	};

	const onCancelHandler = () => {
		setShowModal(false);
	};

	const onSaveHandler = async () => {
		const newRow = await createNewRow(row);
		if (row.notifyCustomer !== "" && row.notifyCustomer !== "No") {
			const notification = getNotificationFromType(row.notifyCustomer, project);
			await createNotification(notification);
		}
		setProject({ ...project, documentRows: [...project.documentRows, newRow] });
	};

	useEffect(() => {
		if (actionSuccessful) {
			setShowModal(false);
			setProject(null);
		}
	}, [actionSuccessful, setShowModal, setProject]);

	return (
		<ModalTemplate
			title='Add Document'
			onCancelHandler={onCancelHandler}
			onSaveHandler={onSaveHandler}
			buttonsDisabled={isLoading}
		>
			<div className='flex flex-col justify-between px-8 py-4'>
				<FormSelectField
					label='Section'
					name='sectionSelect'
					options={[
						{ value: "Enquiries", label: "Enquiries" },
						{ value: "WIP", label: "In Process" },
						{ value: "Completed", label: "Completed" },
					]}
					value={row.section}
					onChange={(e) => setRow({ ...row, section: e.target.value })}
					flexRow={true}
					customTailwind='text-supplyPink font-semibold'
				/>
				<div className='flex items-center mt-2 mb-4'>
					<label className='w-4/12 text-supplyPink font-semibold'>Is Customer able to edit?</label>
					<div className='flex items-center mr-10 w-2/12 px-4'>
						<input
							className='h-10 mr-4'
							type='checkbox'
							checked={!row.customerEdit}
							onChange={() => setRow({ ...row, customerEdit: false })}
							id='customerNoEdit'
						/>
						<label className='text-supplyPink font-semibold select-none' htmlFor='customerNoEdit'>
							No
						</label>
					</div>
					<div className='flex items-center mr-10'>
						<input
							className='h-10 mr-4'
							type='checkbox'
							checked={row.customerEdit}
							onChange={() => setRow({ ...row, customerEdit: true })}
							id='customerYesEdit'
						/>{" "}
						<label className='text-supplyPink font-semibold select-none' htmlFor='customerYesEdit'>
							Yes
						</label>
					</div>
				</div>
				<div className='flex items-center mt-2 mb-4'>
					<label className='text-supplyPink font-semibold mr-4 w-6/12'>Upload File Here</label>
					<div className='flex flex-col w-full'>
						{!row.files && (
							<input
								type='file'
								accept='image/*, .pdf'
								className='w-full grow p-2 bg-slate-300 border'
								multiple
								onChange={(e) => uploadDocumentsHandler(e.target.files)}
							/>
						)}
						{row.files && (
							<div className='flex justify-between '>
								<p>{row.files.length} file(s) attached</p>
								<button className='text-blue-500' onClick={() => setRow({ ...row, files: null })}>
									Clear
								</button>
							</div>
						)}
						<p className='text-supplyPink'>* up to 6 files at one time</p>
					</div>
				</div>
				<FormSelectField
					label='Notify Customer?'
					name='notifyCustomerSelect'
					options={[
						{ value: "No", label: "Don't Notify Customer" },
						{ value: "UploadDoc", label: "Upload a Document" },
						{ value: "CheckDoc", label: "Check a Document" },
						{ value: "SignDoc", label: "Sign a Document" },
						{ value: "UploadImg", label: "Upload Images" },
						{ value: "SiteAccess", label: "Add Site Access Information" },
						{ value: "UploadGoogle", label: "Upload Google Location" },
						{ value: "ContactUs", label: "Request Customer to Contact" },
						{ value: "Review", label: "Leave us a Review" },
					]}
					value={row.notifyCustomer}
					onChange={(e) => setRow({ ...row, notifyCustomer: e.target.value })}
					flexRow={true}
					customTailwind='text-supplyPink font-semibold'
				/>
			</div>
		</ModalTemplate>
	);
}
