import axios from "axios";
import { UserModel } from "../../auth/models/user.model";
import { authRoute, axiosErrorHandler, config } from "../../../app/api";
import { InviteModel } from "../models/invite.model";

export const completeInviteApi = async (
	invite: InviteModel,
	userToken: string
) => {
	try {
		const { data } = await axios.post<UserModel>(
			`${authRoute}/invite`,
			{ invite },
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
