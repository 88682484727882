import { useState } from "react";
import { EmployeeModel } from "../models/employee.model";
import { useDispatch } from "react-redux";
import { firebaseAuth } from "../../../app/firebase";
import { getEmployeesApi } from "../api/get.user";
import {
	deleteEmployees,
	setEmployees,
	updateEmployees,
} from "../store/employee.slice";
import { toast } from "react-toastify";
import { createEmployeeApi } from "../api/post.user";
import { deleteEmployeeApi } from "../api/delete.user";
import { updateEmployeeApi } from "../api/put.user";

export default function useEmployees() {
	const [isLoading, setIsLoading] = useState(false);
	const [actionSuccessful, setActionSuccessful] = useState(false);
	const dispatch = useDispatch();

	const getEmployees = async () => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const employees = await getEmployeesApi(idToken);
				dispatch(setEmployees(employees));
				setActionSuccessful(true);
			} catch (error) {
				console.log(error);
				toast.error("Error retrieving employees");
				dispatch(setEmployees([]));
			} finally {
				setIsLoading(false);
			}
		});
	};

	const createEmployee = async (employee: EmployeeModel) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const updatedEmployee = await createEmployeeApi(employee, idToken);
				dispatch(updateEmployees(updatedEmployee));
				setActionSuccessful(true);
				toast.success("Employee created");
			} catch (error) {
				console.log(error);
				toast.error("Error creating employee");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const updateEmployee = async (employee: EmployeeModel) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const updatedEmployee = await updateEmployeeApi(employee, idToken);
				dispatch(updateEmployees(updatedEmployee));
				setActionSuccessful(true);
			} catch (error) {
				console.log(error);
				toast.error("Error updating employee");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const deleteEmployee = async (employee: EmployeeModel) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const deletedEmployee = await deleteEmployeeApi(employee, idToken);
				dispatch(deleteEmployees(deletedEmployee));
				setActionSuccessful(true);
				toast.success("Employee deleted");
			} catch (error) {
				console.log(error);
				toast.error("Error deleting employee");
			} finally {
				setIsLoading(false);
			}
		});
	};

	return {
		isLoading,
		actionSuccessful,
		getEmployees,
		createEmployee,
		updateEmployee,
		deleteEmployee,
	};
}
