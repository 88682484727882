import React, { useEffect, useState } from "react";
import ModalTemplate from "../../../app/components/ui/modals/ModalTemplate";
import { toast } from "react-toastify";
import { ClientModel } from "../models/client.model";
import FormTextField from "../../../app/components/ui/forms/FormTextField";
import useClients from "../hooks/useClients";

interface Props {
	clientToEdit?: ClientModel | null;
	setShowModal: Function;
	type: string;
}
export default function ClientModal({
	clientToEdit,
	setShowModal,
	type,
}: Props) {
	const [client, setClient] = useState<ClientModel>({
		id: "",
		associatedUser: "",
		contactName: "",
		companyName: "",
		telephone: "",
		email: "",
		userDetailsClient: {
			id: "",
			firebaseId: "",
			type: "",
			employeeDetails: null,
			clientDetails: null,
		},
	});
	const { isLoading, actionSuccessful, createClient, updateClient } =
		useClients();

	const onCancelHandler = () => {
		setShowModal(false);
	};

	const onSaveHandler = async () => {
		if (type === "NEW") {
			await createClient(client);
		} else if (type === "EDIT") {
			await updateClient(client);
		}
	};

	useEffect(() => {
		if (actionSuccessful) {
			setShowModal(false);
		}
	}, [actionSuccessful]);

	useEffect(() => {
		if (type === "EDIT" && clientToEdit) {
			setClient({
				id: clientToEdit?.id,
				associatedUser: clientToEdit?.associatedUser,
				contactName: clientToEdit?.contactName,
				companyName: clientToEdit?.companyName,
				telephone: clientToEdit?.telephone,
				email: clientToEdit?.email,
				userDetailsClient: clientToEdit.userDetailsClient,
			});
		}
	}, [type, clientToEdit]);

	return (
		<ModalTemplate
			title='Add New Client'
			onCancelHandler={() => onCancelHandler()}
			onSaveHandler={() => onSaveHandler()}
			buttonsDisabled={isLoading}
		>
			<div className='flex justify-between px-8 py-4'>
				{/* Left */}
				<div className='flex flex-col w-5/12'>
					<FormTextField
						label='Contact Name *'
						name='contactTextInput'
						value={client?.contactName || ""}
						onChange={(e) =>
							setClient({ ...client, contactName: e.target.value })
						}
					/>
					<FormTextField
						label='Telephone *'
						name='telephoneTextInput'
						value={client?.telephone || ""}
						onChange={(e) =>
							setClient({ ...client, telephone: e.target.value })
						}
					/>
				</div>
				{/* Right */}
				<div className='flex flex-col w-5/12'>
					<FormTextField
						label='Company/School Name *'
						name='companyTextInput'
						value={client?.companyName || ""}
						onChange={(e) =>
							setClient({ ...client, companyName: e.target.value })
						}
					/>
					<FormTextField
						label='Email *'
						name='emailTextInput'
						value={client?.email || ""}
						onChange={(e) => setClient({ ...client, email: e.target.value })}
					/>
				</div>
			</div>
		</ModalTemplate>
	);
}
