import React from "react";
import { AddressModel } from "../../../models/address.model";

interface Props {
	label: string;
	address: AddressModel;
	setAddress: Function;
}
export default function FormAddressField({
	label,
	address,
	setAddress,
}: Props) {
	return (
		<div className='flex flex-col w-full mt-2 mb-4'>
			<label
				htmlFor='addrOneInput'
				className='text-supplyBlue mb-2 select-none'
			>
				{label}
			</label>
			<input
				className='w-full p-2 h-10 bg-slate-300 text-supplyBlue placeholder-slate-500 outline-none'
				id='addrOneInput'
				name='addrOneInput'
				placeholder='Address Line One'
				value={address.addrOne}
				onChange={(e) => setAddress({ ...address, addrOne: e.target.value })}
			/>
			<input
				className='w-full p-2 h-10 bg-slate-300 text-supplyBlue placeholder-slate-500 outline-none'
				id='addrTwoInput'
				name='addrTwoInput'
				placeholder='Address Line Two'
				value={address.addrTwo}
				onChange={(e) => setAddress({ ...address, addrTwo: e.target.value })}
			/>
			<input
				className='w-full p-2 h-10 bg-slate-300 text-supplyBlue placeholder-slate-500 outline-none'
				id='cityInput'
				name='cityInput'
				placeholder='Town/City'
				value={address.city}
				onChange={(e) => setAddress({ ...address, city: e.target.value })}
			/>
			<input
				className='w-full p-2 h-10 bg-slate-300 text-supplyBlue placeholder-slate-500 outline-none'
				id='postcodeInput'
				name='postcodeInput'
				placeholder='Postcode'
				value={address.postcode}
				onChange={(e) => setAddress({ ...address, postcode: e.target.value })}
			/>
		</div>
	);
}
