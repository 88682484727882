import React, { useState } from "react";
import { firebaseAuth } from "../../../app/firebase";
import { getInviteApi } from "../api/get.invite";
import { InviteModel } from "../models/invite.model";
import { toast } from "react-toastify";
import {
	browserLocalPersistence,
	browserSessionPersistence,
	createUserWithEmailAndPassword,
	setPersistence,
} from "firebase/auth";
import { completeInviteApi } from "../api/post.invite";
import { setUser } from "../../auth/store/auth.slice";
import { useDispatch } from "react-redux";

export default function useInvite() {
	const [inviteLoaded, setInviteLoaded] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [invite, setInvite] = useState<InviteModel | null>(null);

	const dispatch = useDispatch();

	const getInvite = async (inviteURL: string | undefined) => {
		setIsLoading(true);
		try {
			const invite = await getInviteApi(inviteURL);
			setInvite(invite);
			setInviteLoaded(true);
		} catch (error) {
			console.log(error);
			toast.error("Error retrieving invite");
		} finally {
			setIsLoading(false);
		}
	};

	const completeInvite = async (password: string, rememberMe: boolean) => {
		setIsLoading(true);
		if (!invite) {
			toast.error("Please refresh");
			return;
		}
		setPersistence(
			firebaseAuth,
			rememberMe ? browserLocalPersistence : browserSessionPersistence
		).then(async () => {
			createUserWithEmailAndPassword(firebaseAuth, invite.email, password)
				.then(async (userCredential) => {
					userCredential.user.getIdToken(true).then(async (idToken) => {
						try {
							const user = await completeInviteApi(invite!, idToken);
							dispatch(setUser(user));
						} catch (error) {
							console.log(error);
							toast.error("Error completing registration");
							await userCredential.user.delete();
						} finally {
							setIsLoading(false);
						}
					});
				})
				.catch((error) => {
					console.log(error);
					toast.error("Authentication error");
					setIsLoading(false);
				});
		});
	};

	return { inviteLoaded, isLoading, invite, getInvite, completeInvite };
}
