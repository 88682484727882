import { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoadingSpinner from "./app/components/ui/LoadingSpinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginScreen from "./features/auth/routes/LoginScreen";
import { useAppSelector } from "./app/hooks/useAppSelector";
import Dashboard from "./features/projects/routes/Dashboard";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "./app/firebase";
import useAuthentication from "./features/auth/hooks/useAuthentication";
import MyAccount from "./features/account/screens/MyAccount";
import ManageUsersScreen from "./features/users/routes/ManageUsersScreen";
import InviteScreen from "./features/invites/routes/InviteScreen";
import ProjectDetail from "./features/projects/routes/ProjectDetail";
import ForgotPassword from "./features/auth/routes/ForgotPassword";

function App() {
	const auth = useAppSelector((state) => state.auth);
	const { signInToken, markNotAuthenticated } = useAuthentication();

	useEffect(() => {
		if (auth.signedIn === null) {
			const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
				if (user) {
					await signInToken(user);
				} else {
					await markNotAuthenticated();
				}
			});
			unsubscribe();
		}
	}, [auth, markNotAuthenticated, signInToken]);

	return (
		<BrowserRouter>
			{auth.signedIn === null ? (
				<div className='flex flex-col w-full h-screen justify-center items-center'>
					<LoadingSpinner message='Welcome to Portal' />
				</div>
			) : auth.signedIn ? (
				// Authenticated Routes
				<Routes>
					<Route path='/' element={<Dashboard />} />
					{auth.user?.type === "EMPLOYEE" && (
						<Route path='/clients' element={<ManageUsersScreen />} />
					)}
					<Route path='/project'>
						<Route path=':projectNumber' element={<ProjectDetail />} />
					</Route>
					<Route path='/my-account' element={<MyAccount />} />
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			) : (
				// UnAuthenticated Routes
				<Routes>
					<Route path='/' element={<LoginScreen />} />
					<Route path='/forgot-password' element={<ForgotPassword />} />
					<Route path='/invite'>
						<Route path=':inviteId' element={<InviteScreen />} />
					</Route>{" "}
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			)}
			<ToastContainer autoClose={2000} position='bottom-left' />
		</BrowserRouter>
	);
}

export default App;
