import axios from "axios";
import {
	axiosErrorHandler,
	clientRoute,
	configDelete,
	employeeRoute,
} from "../../../app/api";
import { ClientModel } from "../models/client.model";
import { EmployeeModel } from "../models/employee.model";

export const deleteClientApi = async (
	client: ClientModel,
	userToken: string
) => {
	try {
		const { data } = await axios.delete<ClientModel>(
			clientRoute,
			configDelete(userToken, client)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};

export const deleteEmployeeApi = async (
	employee: EmployeeModel,
	userToken: string
) => {
	try {
		const { data } = await axios.delete<EmployeeModel>(
			employeeRoute,
			configDelete(userToken, employee)
		);
		return data;
	} catch (error) {
		axiosErrorHandler(error);
	}
};
