import axios from "axios";
import {
	axiosErrorHandler,
	clientRoute,
	config,
	employeeRoute,
} from "../../../app/api";
import { ClientModel } from "../models/client.model";
import { EmployeeModel } from "../models/employee.model";

export const createClientApi = async (
	client: ClientModel,
	userToken: string
) => {
	try {
		const { data } = await axios.post(
			clientRoute,
			{ client },
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};

export const createEmployeeApi = async (
	employee: EmployeeModel,
	userToken: string
) => {
	try {
		const { data } = await axios.post(
			employeeRoute,
			{ employee },
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
