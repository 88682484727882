import { useState } from "react";
import { firebaseAuth } from "../../../app/firebase";
import { createDocumentApi, createRowApi } from "../api/post.document";
import { toast } from "react-toastify";
import { RowModel } from "../models/row.model";
import useFirebaseStorage from "../../../app/hooks/useFirebaseStorage";
import { DocumentModel } from "../models/document.model";
import { updateDocumentApi } from "../api/put.document";
import { deleteDocumentApi } from "../api/delete.document";

export default function useDocuments() {
	const [isLoading, setIsLoading] = useState(false);
	const [actionSuccessful, setActionSuccessful] = useState(false);

	const { uploadDocument } = useFirebaseStorage();

	// Function to trim extension from filename
	const getFileName = (filename: string) => {
		const name = filename.substring(0, filename.lastIndexOf(".")) || filename;
		return name;
	};

	const createNewDocument = async (files: FileList, associatedRow: string) => {
		setIsLoading(true);
		// Create document array with firebase URLs
		let documentArray: Array<DocumentModel> = [];
		for (let i = 0; i < files.length; i++) {
			const upload = await uploadDocument(files[i], i);
			documentArray.push({
				associatedRow: associatedRow,
				name: getFileName(files[i].name),
				url: upload,
				type: files[i].type,
				complete: false,
			});
		}
		// Post document array to server
		const newDocuments = await firebaseAuth.currentUser
			?.getIdToken(true)
			.then(async (idToken) => {
				try {
					const newDocuments = await createDocumentApi(documentArray, idToken);
					setActionSuccessful(true);
					toast.success("Documents Uploaded");
					return newDocuments;
				} catch (error) {
					console.log(error);
					toast.error("Error uploading documents");
					return null;
				} finally {
					setIsLoading(false);
				}
			});
		return newDocuments;
	};

	const markDocumentComplete = async (document: DocumentModel) => {
		const updateDocument = { ...document, complete: true };
		setIsLoading(true);
		const updatedDocuments = await firebaseAuth.currentUser
			?.getIdToken(true)
			.then(async (idToken) => {
				try {
					const updatedDocument = await updateDocumentApi(
						updateDocument,
						idToken
					);
					setActionSuccessful(true);
					toast.success("Marked Document Complete");
					return updatedDocument;
				} catch (error) {
					console.log(error);
					toast.error("Error marking document complete");
					return null;
				} finally {
					setIsLoading(false);
				}
			});
		return updatedDocuments;
	};

	const deleteDocument = async (document: DocumentModel) => {
		setIsLoading(true);
		const deletedDocument = await firebaseAuth.currentUser
			?.getIdToken(true)
			.then(async (idToken) => {
				try {
					const deletedDocument = await deleteDocumentApi(document, idToken);
					setActionSuccessful(true);
					toast.success("Document Deleted");
					return deletedDocument;
				} catch (error) {
					console.log(error);
					toast.error("Error deleting document");
					return null;
				} finally {
					setIsLoading(false);
				}
			});
		return deletedDocument;
	};

	const createNewRow = async (row: RowModel) => {
		setIsLoading(true);

		const newRow = await firebaseAuth.currentUser
			?.getIdToken(true)
			.then(async (idToken) => {
				try {
					const newRow = await createRowApi(row, idToken);
					if (row.files) {
						let documentArray: Array<DocumentModel> = [];
						for (let i = 0; i < row.files.length; i++) {
							const upload = await uploadDocument(row.files[i], i);
							documentArray.push({
								associatedRow: newRow.id,
								name: getFileName(row.files[i].name),
								url: upload,
								type: row.files[i].type,
								complete: false,
							});
						}
						const newDocuments = await createDocumentApi(
							documentArray,
							idToken
						);
						setActionSuccessful(true);
						toast.success("Row created");
						return newDocuments;
					}
					setActionSuccessful(true);
					toast.success("Row created");
					return newRow;
				} catch (error) {
					console.log(error);
					toast.error("Error creating row");
					return null;
				} finally {
					setIsLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
		return newRow || null;
	};

	return {
		isLoading,
		actionSuccessful,
		createNewDocument,
		markDocumentComplete,
		deleteDocument,
		createNewRow,
	};
}
