import { createSlice } from "@reduxjs/toolkit";
import { EmployeeModel } from "../models/employee.model";
import { clearUserData } from "../../auth/store/auth.slice";

interface EmployeeState {
	employees: Array<EmployeeModel>;
	loaded: boolean;
}

const initialState: EmployeeState = {
	employees: [],
	loaded: false,
};

export const employeeSlice = createSlice({
	name: "employee",
	initialState,
	reducers: {
		setEmployees: (state, action) => {
			state.employees = action.payload;
			state.loaded = true;
		},
		updateEmployees: (state, action) => {
			const newState = state.employees.filter(
				(employee) => employee.id !== action.payload.id
			);
			state.employees = [...newState, action.payload];
			state.loaded = true;
		},
		deleteEmployees: (state, action) => {
			const newState = state.employees.filter(
				(employee) => employee.id !== action.payload.id
			);
			state.employees = newState;
			state.loaded = true;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(clearUserData, () => {
			return initialState;
		});
	},
});

export const { setEmployees, updateEmployees, deleteEmployees } =
	employeeSlice.actions;
export default employeeSlice.reducer;
