import { createElement } from "react";
import ModalTemplate from "../../../app/components/ui/modals/ModalTemplate";
import { DocumentModel } from "../models/document.model";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import useDocuments from "../hooks/useDocuments";
import { useAppSelector } from "../../../app/hooks/useAppSelector";

interface Props {
	setShowModal: Function;
	doc: DocumentModel;
	setDocument: Function;
	setProject: Function;
}

export default function DocumentPreviewModal({
	setShowModal,
	doc,
	setDocument,
	setProject,
}: Props) {
	const user = useAppSelector((state) => state.auth.user);
	const { isLoading, markDocumentComplete, deleteDocument } = useDocuments();
	const onCancelHandler = () => {
		setShowModal(false);
		setDocument(null);
	};

	const onSaveHandler = () => {
		//
	};

	const downloadDocumentHandler = () => {
		const storage = getStorage();
		getDownloadURL(ref(storage, doc.url))
			.then((url) => {
				const xhr = new XMLHttpRequest();
				xhr.responseType = "blob";
				xhr.onload = (event) => {
					const blob = xhr.response;
					const blobURL = URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = blobURL;
					link.setAttribute("download", doc.name);
					link.click();
				};
				xhr.open("GET", url);
				xhr.send();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const deleteDocumentHandler = async () => {
		await deleteDocument(doc);
		setProject(null);
	};

	const completeDocumentHandler = async () => {
		await markDocumentComplete(doc);
		setProject(null);
	};

	console.log(document);

	return (
		<ModalTemplate
			title='Document View'
			onCancelHandler={onCancelHandler}
			onSaveHandler={onSaveHandler}
			buttonsDisabled={false}
			hideButton={true}
		>
			<div className='flex flex-col items-center justify-center'>
				<div className='flex flex-col items-center justify-center h-80 w-10/12 bg-slate-100 mt-4 mb-2'>
					<object data={doc.url} type={doc.type} width='100%' height='100%'>
						<p>Unable to display preview</p>
					</object>
				</div>
				<div className='flex w-10/12 justify-between mb-8'>
					<div className='flex flex-col'>
						<button
							className='text-supplyPink text-sm text-left'
							onClick={() => downloadDocumentHandler()}
							disabled={isLoading}
						>
							Download Document
						</button>
						<button
							className='text-supplyPink text-sm text-left'
							onClick={() => deleteDocumentHandler()}
							disabled={isLoading}
						>
							Delete Document
						</button>
					</div>
					{!doc.complete && user?.type === "EMPLOYEE" && (
						<div className='flex flex-col'>
							<button
								className='bg-supplyPink text-white px-4 py-1 rounded-2xl shadow-2xl text-sm mb-4'
								onClick={() => completeDocumentHandler()}
								disabled={isLoading}
							>
								Mark as Complete
							</button>
						</div>
					)}
				</div>
				<button
					className='bg-supplyBlue text-white px-4 py-1 rounded-3xl shadow-2xl text-sm mb-4'
					onClick={() => onCancelHandler()}
				>
					Close
				</button>
			</div>
		</ModalTemplate>
	);
}
