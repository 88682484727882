import { Link, NavLink } from "react-router-dom";
import useAuthentication from "../../../../features/auth/hooks/useAuthentication";
import Logo from "../../Logo";
import { HiBellAlert } from "react-icons/hi2";
import NotificationList from "../../../../features/notifications/components/NotificationList";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import useNotifications from "../../../../features/notifications/hooks/useNotifications";

export default function Header() {
	const notificationState = useAppSelector((state) => state.notification);
	const { auth } = useAuthentication();
	const { signOutUser } = useAuthentication();
	const [showNotifications, setShowNotifications] = useState(false);
	const { getNotifications } = useNotifications();

	const signOutHandler = async () => {
		await signOutUser();
	};

	useEffect(() => {
		if (!notificationState.notifications && auth.signedIn) {
			getNotifications();
		}
	}, [notificationState.notifications, getNotifications, auth.signedIn]);

	return (
		<div className='flex relative justify-between items-center bg-supplyBlue text-white pt-8 px-20 pb-20'>
			<Link to='https://supplyingdirectservices.co.uk/' target='_blank' rel='noopener noreferrer'>
				<Logo />
			</Link>
			<ul className='flex items-center list-none space-x-8 px-20 text-lg font-semibold'>
				{auth.user?.type !== "EMPLOYEE" && (
					<li>
						<Link
							to='https://supplyingdirectservices.co.uk/contact-us'
							target='_blank'
							rel='noopener noreferrer'
						>
							Get a quote
						</Link>
					</li>
				)}
				{auth.user?.type !== "EMPLOYEE" && (
					<li>
						<Link
							to='https://supplyingdirectservices.co.uk/'
							target='_blank'
							rel='noopener noreferrer'
						>
							Services
						</Link>
					</li>
				)}
				{auth.signedIn && (
					<li>
						<NavLink to='/' className={({ isActive }) => (isActive ? "text-supplyPink" : "")}>
							Dashboard
						</NavLink>
					</li>
				)}
				{auth.user?.type === "EMPLOYEE" && (
					<li>
						<NavLink
							to='/clients'
							className={({ isActive }) => (isActive ? "text-supplyPink" : "")}
						>
							Users
						</NavLink>
					</li>
				)}
				{auth.signedIn && auth.user?.type === "CLIENT" && (
					<li>
						<NavLink
							to='/my-account'
							className={({ isActive }) => (isActive ? "text-supplyPink" : "")}
						>
							My Account
						</NavLink>
					</li>
				)}
				<li>
					{!auth.signedIn && (
						<NavLink to='/' className={({ isActive }) => (isActive ? "text-supplyPink" : "")}>
							Log In
						</NavLink>
					)}
					{auth.signedIn && (
						<button className='' onClick={() => signOutHandler()}>
							Log Out
						</button>
					)}
				</li>
				{auth.signedIn && (
					<li className='flex relative'>
						<button onClick={() => setShowNotifications(!showNotifications)}>
							<HiBellAlert />
						</button>
						{notificationState.notifications &&
							notificationState.notifications.filter((notification) => !notification.read).length >
								0 && (
								<p className='flex justify-center items-center absolute -bottom-2 -right-4 text-xs bg-supplyPink rounded-full w-4 h-4'>
									{
										notificationState.notifications.filter((notification) => !notification.read)
											.length
									}
								</p>
							)}
					</li>
				)}
			</ul>
			{showNotifications && <NotificationList />}
		</div>
	);
}
