import { authRoute, axiosErrorHandler, config } from "../../../app/api";
import { UserModel } from "../models/user.model";
import axios from "axios";

export const getUserApi = async (userToken: string) => {
	try {
		const response = await axios.get<UserModel>(authRoute, config(userToken));
		const user = response.data;
		return user;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
