import React, { ChangeEventHandler } from "react";

interface Option {
	label: string;
	value: string;
}

interface Props {
	label: string;
	name: string;
	options: Array<Option>;
	value: string;
	onChange: ChangeEventHandler<HTMLSelectElement>;
	flexRow?: boolean;
	customTailwind?: string;
}

export default function FormSelectField({
	label,
	name,
	options,
	value,
	onChange,
	flexRow,
	customTailwind,
}: Props) {
	return (
		<div
			className={`flex ${
				flexRow ? "flex-row items-center" : "flex-col"
			} grow mt-2 mb-4`}
		>
			<label
				htmlFor={name}
				className={`${
					flexRow ? "mb-0 mr-4 w-6/12" : "mb-2 mr-0"
				} ${customTailwind}`}
			>
				{label}
			</label>
			<select
				className='w-full p-2 h-10 bg-slate-300 text-supplyBlue border'
				id={name}
				name={name}
				value={value}
				onChange={onChange}
			>
				<option disabled value=''>
					Select an option
				</option>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
}
