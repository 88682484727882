import axios from "axios";
import {
	axiosErrorHandler,
	clientRoute,
	config,
	employeeRoute,
} from "../../../app/api";
import { ClientModel } from "../models/client.model";
import { EmployeeModel } from "../models/employee.model";

export const getClientsApi = async (userToken: string) => {
	try {
		const { data } = await axios.get<Array<ClientModel>>(
			clientRoute,
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};

export const getEmployeesApi = async (userToken: string) => {
	try {
		const { data } = await axios.get<Array<EmployeeModel>>(
			employeeRoute,
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
