import React from "react";

interface Props {
	title: string;
}

export default function PageTitle({ title }: Props) {
	return (
		<h3 className='px-20 py-6 bg-gradient-to-b from-supplyPink to-supplyBlue text-white rounded-xl shadow-2xl shadow-black text-xl'>
			{title}
		</h3>
	);
}
