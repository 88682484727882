import Table from "../../../app/components/Table";
import { EmployeeModel } from "../models/employee.model";
import ToolTipIcon from "../../../app/components/ui/ToolTipIcon";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import useEmployees from "../hooks/useEmployees";
import { useState } from "react";
import EmployeeModal from "./EmployeeModal";

export default function EmployeeTable({
	employees,
}: {
	employees: Array<EmployeeModel>;
}) {
	const [showEditEmployeeModal, setShowEditEmployeeModal] = useState(false);
	const [employeeToEdit, setEmployeeToEdit] = useState<EmployeeModel | null>(
		null
	);
	const { deleteEmployee } = useEmployees();

	const deleteEmployeeHandler = async (employee: EmployeeModel) => {
		await deleteEmployee(employee);
	};

	const editEmployeeHandler = (employee: EmployeeModel) => {
		setEmployeeToEdit(employee);
		setShowEditEmployeeModal(true);
	};

	return (
		<>
			<Table
				columns={[
					{ title: "Name" },
					{ title: "Email" },
					{ title: "Super Admin" },
					{ title: "Status" },
					{ title: "Actions" },
				]}
				rows={employees.map((employee) => ({
					fields: [
						{ value: employee.employeeName },
						{ value: employee.email },
						{ value: employee.isSuperAdmin ? "Yes" : "No" },
						{
							value: employee.userDetailsEmployee.firebaseId
								? "Registered"
								: "Invited",
						},
						{
							value: (
								<div className='flex justify-center space-x-4'>
									<button onClick={() => editEmployeeHandler(employee)}>
										<ToolTipIcon
											message='Edit Employee'
											icon={<AiOutlineEdit size={18} />}
										/>
									</button>
									<button onClick={() => deleteEmployeeHandler(employee)}>
										<ToolTipIcon
											message='Delete Employee'
											icon={<AiOutlineDelete size={18} />}
										/>
									</button>
								</div>
							),
						},
					],
				}))}
			/>
			{showEditEmployeeModal && (
				<EmployeeModal
					employeeToEdit={employeeToEdit}
					setShowModal={setShowEditEmployeeModal}
					type='EDIT'
				/>
			)}
		</>
	);
}
