import React, { ChangeEventHandler } from "react";

interface Props {
	label: string;
	name: string;
	value: string;
	onChange: ChangeEventHandler<HTMLTextAreaElement>;
}

export default function FormTextAreaField({
	label,
	name,
	value,
	onChange,
}: Props) {
	return (
		<div className='flex flex-col w-full mt-2 mb-4'>
			<label htmlFor={name} className='text-supplyBlue mb-2 select-none'>
				{label}
			</label>
			<textarea
				rows={8}
				className='w-full p-2 bg-slate-300 text-supplyBlue border'
				value={value}
				onChange={onChange}
			/>
		</div>
	);
}
