import { HiBellAlert } from "react-icons/hi2";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { useNavigate } from "react-router-dom";
import { NotificationModel } from "../models/notification.model";
import useNotifications from "../hooks/useNotifications";

export default function NotificationList() {
	const notificationState = useAppSelector((state) => state.notification);

	const { markNotificationRead } = useNotifications();

	const navigate = useNavigate();

	const notificationClickHandler = async (notification: NotificationModel) => {
		if (!notification.read) {
			await markNotificationRead(notification);
		}
		navigate(`/project/${notification.projectNumber}`);
	};

	return (
		<div className='flex flex-col w-80 absolute right-0 top-20'>
			<div className='px-2 py-4 bg-gradient-to-r from-supplyBlue to-supplyPink'>
				<p>Notifications</p>
			</div>
			<div className='px-2 py-4 bg-supplyPink'>
				{(!notificationState.notifications || notificationState.notifications.length === 0) && (
					<p>No new notifications</p>
				)}
				{notificationState.notifications &&
					notificationState.notifications.map((notification) => (
						<button
							key={notification.id}
							onClick={() => notificationClickHandler(notification)}
							className='flex mb-4 items-center justify-end'
						>
							{!notification.read && <HiBellAlert size={20} />}
							<p className='text-xs text-left w-10/12 ml-4'>{notification.notificationText}</p>
						</button>
					))}
			</div>
		</div>
	);
}
