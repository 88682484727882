import { createSlice } from "@reduxjs/toolkit";
import { ClientModel } from "../models/client.model";
import { clearUserData } from "../../auth/store/auth.slice";

interface ClientState {
	clients: Array<ClientModel>;
	loaded: boolean;
}

const initialState: ClientState = {
	clients: [],
	loaded: false,
};

export const clientSlice = createSlice({
	name: "client",
	initialState,
	reducers: {
		setClients: (state, action) => {
			state.clients = action.payload;
			state.loaded = true;
		},
		updateClients: (state, action) => {
			const newState = state.clients?.filter(
				(client) => client.id !== action.payload.id
			);
			state.clients = [...newState, action.payload];
			state.loaded = true;
		},
		deleteClients: (state, action) => {
			const newState = state.clients?.filter(
				(client) => client.id !== action.payload.id
			);
			state.clients = newState;
			state.loaded = true;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(clearUserData, () => {
			return initialState;
		});
	},
});

export const { setClients, updateClients, deleteClients } = clientSlice.actions;
export default clientSlice.reducer;
