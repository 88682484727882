import React from "react";
import { DocumentModel } from "../models/document.model";
import { IoDocumentText } from "react-icons/io5";

interface Props {
	document: DocumentModel;
	setShowDocumentPreview: Function;
}

export default function Document({ document, setShowDocumentPreview }: Props) {
	return (
		<button
			className='flex items-center w-48 h-24 shadow-xl text-left'
			onClick={() => setShowDocumentPreview(document)}
		>
			<div className='flex w-40 p-3 text-sm items-center'>
				<p className='text-supplyPink w-8/12 text-xs'>{document.name}</p>
				<IoDocumentText size={40} />
			</div>
			{document.complete && (
				<div
					className='flex flex-col w-8 h-full rotate-180 text-xs p-2 bg-supplyPink text-white text-center'
					style={{ writingMode: "vertical-rl" }}
				>
					<p>Complete</p>
				</div>
			)}
		</button>
	);
}
