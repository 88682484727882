import { createSlice } from "@reduxjs/toolkit";
import { ProjectModel } from "../models/project.model";
import { clearUserData } from "../../auth/store/auth.slice";

interface ProjectState {
	projects: Array<ProjectModel>;
	loaded: boolean;
}

const initialState: ProjectState = {
	projects: [],
	loaded: false,
};

export const projectSlice = createSlice({
	name: "project",
	initialState,
	reducers: {
		setProjects: (state, action) => {
			state.projects = action.payload;
			state.loaded = true;
		},
		updateProject: (state, action) => {
			const newState = state.projects.filter((project) => project.id !== action.payload.id);
			state.projects = [...newState, action.payload];
			state.loaded = true;
		},
		deleteProjectAction: (state, action) => {
			const newState = state.projects.filter((project) => project.id !== action.payload.id);
			state.projects = newState;
			state.loaded = true;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(clearUserData, () => {
			return initialState;
		});
	},
});

export const { setProjects, updateProject, deleteProjectAction } = projectSlice.actions;
export default projectSlice.reducer;
