import { useRef, useState } from "react";
import NotificationModal from "../../notifications/components/NotificationModal";
import useDocuments from "../hooks/useDocuments";
import { RowModel } from "../models/row.model";
import Document from "./Document";
import LoadingIcon from "../../../app/components/ui/LoadingIcon";
import DocumentPreviewModal from "./DocumentPreviewModal";
import { DocumentModel } from "../models/document.model";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { ProjectModel } from "../../projects/models/project.model";

interface Props {
	row: RowModel;
	project: ProjectModel;
	setProject: Function;
}

export default function DocumentRow({ row, project, setProject }: Props) {
	const user = useAppSelector((state) => state.auth.user);
	const [docToPreview, setDocToPreview] = useState<DocumentModel | null>(null);
	const [showDocumentPreviewModal, setShowDocumentPreviewModal] = useState(false);
	const [showAddNotificationModal, setShowAddNotificationModal] = useState(false);

	const documentInput = useRef<HTMLInputElement>(null);

	const { isLoading, createNewDocument } = useDocuments();

	const addDocumentHandler = async (files: FileList | null) => {
		if (files) {
			await createNewDocument(files, row.id);
			setProject(null);
		}
	};

	const showDocumentPreviewHandler = (document: DocumentModel) => {
		setDocToPreview(document);
		setShowDocumentPreviewModal(true);
	};

	return (
		<div className='flex items-center justify-between w-full min-h-[8rem] bg-white shadow-xl mb-4 px-8'>
			<div className='flex w-[400px] h-full items-center justify-end p-2'>
				<input
					type='file'
					accept='image/*,.pdf'
					className='hidden'
					ref={documentInput}
					onChange={(e) => addDocumentHandler(e.target.files)}
					multiple
				/>
				{(user?.type === "EMPLOYEE" || (user?.type === "CLIENT" && row.customerEdit)) && (
					<button
						className='text-supplyBlue font-semibold mr-8'
						onClick={() => documentInput?.current?.click()}
						disabled={isLoading}
					>
						{isLoading ? <LoadingIcon /> : "Add Documents +"}
					</button>
				)}
				{row.rowDocuments && row.rowDocuments.length > 0 && (
					<Document
						document={row.rowDocuments[0]}
						setShowDocumentPreview={showDocumentPreviewHandler}
					/>
				)}
				{!row.rowDocuments || (row.rowDocuments.length === 0 && <div className='w-48'></div>)}
			</div>
			<div className='flex flex-wrap items-center ml-10 w-[550px] grow p-2'>
				{row.rowDocuments &&
					row.rowDocuments
						.slice(1)
						.map((document) => (
							<Document
								key={document.id}
								document={document}
								setShowDocumentPreview={showDocumentPreviewHandler}
							/>
						))}
			</div>
			{user?.type === "EMPLOYEE" && (
				<button className='text-supplyBlue' onClick={() => setShowAddNotificationModal(true)}>
					Notify Customer?
				</button>
			)}
			{showAddNotificationModal && (
				<NotificationModal setShowModal={setShowAddNotificationModal} project={project} />
			)}
			{showDocumentPreviewModal && docToPreview && (
				<DocumentPreviewModal
					setShowModal={setShowDocumentPreviewModal}
					doc={docToPreview}
					setDocument={setDocToPreview}
					setProject={setProject}
				/>
			)}
		</div>
	);
}
