import { useState } from "react";
import { useDispatch } from "react-redux";
import { LoginModel } from "../models/login.model";
import { UserModel } from "../models/user.model";
import { toast } from "react-toastify";
import { clearUserData, setUser } from "../store/auth.slice";
import {
	browserLocalPersistence,
	browserSessionPersistence,
	setPersistence,
	signInWithEmailAndPassword,
	signOut,
} from "firebase/auth";
import { firebaseAuth } from "../../../app/firebase";
import { getUserApi } from "../api/get.auth";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { mapAuthCodeToMessage } from "../utils/firebaseAuthErrorHandling";

export default function useAuthentication() {
	const auth = useAppSelector((state) => state.auth);

	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const signInUser = async (login: LoginModel) => {
		setIsLoading(true);
		setPersistence(
			firebaseAuth,
			login.rememberMe ? browserLocalPersistence : browserSessionPersistence
		).then(async () => {
			signInWithEmailAndPassword(firebaseAuth, login.email, login.password)
				.then(async (result: any) => {
					var userToken = result.user.accessToken;
					try {
						const user = await getUserApi(userToken);
						const userPayload: UserModel = {
							id: user.id,
							firebaseId: user.firebaseId,
							type: user.type,
							employeeDetails: user.employeeDetails,
							clientDetails: user.clientDetails,
						};
						dispatch(setUser(userPayload));
					} catch (error) {
						console.log(error);
						toast.error("Error login");
					} finally {
						setIsLoading(false);
					}
				})
				.catch((error) => {
					const message = mapAuthCodeToMessage(error.code);
					console.log(error);
					toast.error(message === "" ? error.code : message);
					setIsLoading(false);
				});
		});
	};

	const signInToken = async (user: any) => {
		setIsLoading(true);
		var userToken = user.accessToken;
		try {
			const user = await getUserApi(userToken);
			const userPayload: UserModel = {
				id: user.id,
				firebaseId: user.firebaseId,
				type: user.type,
				employeeDetails: user.employeeDetails,
				clientDetails: user.clientDetails,
			};
			dispatch(setUser(userPayload));
		} catch (error) {
			console.log(error);
			dispatch(clearUserData(null));
		} finally {
			setIsLoading(false);
		}
	};

	const signOutUser = async () => {
		setIsLoading(true);
		signOut(firebaseAuth)
			.then(() => {
				dispatch(clearUserData(null));
			})
			.catch((error) => {
				console.log(error);
				toast.error("Error signout");
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const markNotAuthenticated = async () => {
		setIsLoading(true);
		dispatch(clearUserData(null));
	};

	return {
		auth,
		isLoading,
		signInUser,
		signInToken,
		signOutUser,
		markNotAuthenticated,
	};
}
