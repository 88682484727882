import Table from "../../../app/components/Table";
import { ClientModel } from "../models/client.model";
import ToolTipIcon from "../../../app/components/ui/ToolTipIcon";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import useClients from "../hooks/useClients";
import { useState } from "react";
import ClientModal from "./ClientModal";

export default function ClientTable({
	clients,
}: {
	clients: Array<ClientModel>;
}) {
	const [showEditClientModal, setShowEditClientModal] = useState(false);
	const [clientToEdit, setClientToEdit] = useState<ClientModel | null>(null);
	const { deleteClient } = useClients();

	const deleteClientHandler = async (client: ClientModel) => {
		await deleteClient(client);
	};

	const editClientHandler = (client: ClientModel) => {
		setClientToEdit(client);
		setShowEditClientModal(true);
	};

	return (
		<>
			<Table
				columns={[
					{ title: "Company" },
					{ title: "Contact" },
					{ title: "Telephone" },
					{ title: "Email" },
					{ title: "Status" },
					{ title: "Actions" },
				]}
				rows={clients.map((client) => ({
					fields: [
						{ value: client?.companyName },
						{ value: client?.contactName },
						{ value: client?.telephone },
						{ value: client?.email },
						{
							value: client.userDetailsClient.firebaseId
								? "Registered"
								: "Invited",
						},
						{
							value: (
								<div className='flex justify-center space-x-4'>
									<button onClick={() => editClientHandler(client)}>
										<ToolTipIcon
											message='Edit Client'
											icon={<AiOutlineEdit size={18} />}
										/>
									</button>
									<button onClick={() => deleteClientHandler(client)}>
										<ToolTipIcon
											message='Delete Client'
											icon={<AiOutlineDelete size={18} />}
										/>
									</button>
								</div>
							),
						},
					],
				}))}
			/>
			{showEditClientModal && (
				<ClientModal
					clientToEdit={clientToEdit}
					setShowModal={setShowEditClientModal}
					type='EDIT'
				/>
			)}
		</>
	);
}
