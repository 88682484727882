import { useState } from "react";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { useDispatch } from "react-redux";
import { firebaseAuth } from "../../../app/firebase";
import { getProjectByNumberApi, getProjectsApi } from "../api/get.project";
import {
	deleteProjectAction,
	setProjects,
	updateProject,
} from "../store/project.slice";
import { toast } from "react-toastify";
import { ProjectModel } from "../models/project.model";
import { AddressModel } from "../../../app/models/address.model";
import { createProjectApi } from "../api/post.project";
import { updateProjectApi } from "../api/put.project";
import { deleteProjectApi } from "../api/delete.project";

export default function useProjects() {
	const projectState = useAppSelector((state) => state.project);
	const [isLoading, setIsLoading] = useState(false);
	const [actionSuccessful, setActionSuccessful] = useState(false);
	const [actionFailed, setActionFailed] = useState(false);
	const dispatch = useDispatch();

	const getProjects = async () => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const projects = await getProjectsApi(idToken);
				dispatch(setProjects(projects));
				setActionSuccessful(true);
			} catch (error) {
				console.log(error);
				toast.error("Error retrieving projects");
				setActionFailed(true);
			} finally {
				setIsLoading(false);
			}
		});
	};

	const getProjectByNumber = async (projectNumber: string) => {
		const project = await firebaseAuth.currentUser
			?.getIdToken(true)
			.then(async (idToken) => {
				try {
					const project = await getProjectByNumberApi(projectNumber, idToken);
					setActionSuccessful(true);
					return project;
				} catch (error) {
					console.log(error);
					toast.error("Error retrieving project");
					setActionFailed(true);
					return null;
				} finally {
					setIsLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			})
			.finally(() => {
				return null;
			});

		return project || null;
	};

	const createProject = async (
		project: ProjectModel,
		address: AddressModel
	) => {
		if (!actionFailed) {
			setIsLoading(true);
			firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
				try {
					const newProject = await createProjectApi(project, address, idToken);
					dispatch(updateProject(newProject));
					setActionSuccessful(true);
					toast.success("Project created");
				} catch (error) {
					console.log(error);
					toast.error("Error creating project");
					setActionFailed(true);
				} finally {
					setIsLoading(false);
				}
			});
		}
	};

	const updateProjectStatus = async (project: ProjectModel, status: string) => {
		const newProject: ProjectModel = {
			...project,
			projectStatus: status,
		};
		if (!actionFailed) {
			setIsLoading(true);
			firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
				try {
					const updatedProject = await updateProjectApi(newProject, idToken);
					dispatch(updateProject(updatedProject));
					setActionSuccessful(true);
				} catch (error) {
					console.log(error);
					toast.error("Error updating project status");
				} finally {
					setIsLoading(false);
				}
			});
		}
	};

	const deleteProject = async (project: ProjectModel) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const deletedProject = await deleteProjectApi(project, idToken);
				dispatch(deleteProjectAction(deletedProject));
				setActionSuccessful(true);
				toast.success("Project deleted");
			} catch (error) {
				console.log(error);
				toast.error("Error deleting project");
			} finally {
				setIsLoading(false);
			}
		});
	};

	return {
		projectState,
		isLoading,
		actionSuccessful,
		getProjects,
		getProjectByNumber,
		createProject,
		updateProjectStatus,
		deleteProject,
	};
}
