import React from "react";

interface Props {
	heading: string;
	value: string;
}

export default function ProjectDetailProperty({ heading, value }: Props) {
	return (
		<p>
			<span className='font-semibold'>{heading}</span>
			{value}
		</p>
	);
}
