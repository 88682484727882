import { ReactNode } from "react";
import Header from "./components/Header";

type Props = {
	children: ReactNode;
};

export default function Layout({ children }: Props) {
	return (
		<div className='flex flex-col min-h-screen font-display'>
			<Header />
			<div className='flex flex-col items-center grow bg-gradient-to-b from-supplyBlue from-[300px] to-[300px] to-white px-20'>
				{children}
			</div>
		</div>
	);
}
