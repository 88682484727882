import React, { useEffect, useState } from "react";
import Layout from "../../../app/components/layout/Layout";
import PageTitle from "../../../app/components/ui/PageTitle";
import ClientModal from "../components/ClientModal";
import useClients from "../hooks/useClients";
import ClientTable from "../components/ClientTable";
import EmployeeModal from "../components/EmployeeModal";
import useAuthentication from "../../auth/hooks/useAuthentication";
import Switch from "../../../app/components/ui/Switch";
import EmployeeTable from "../components/EmployeeTable";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import useEmployees from "../hooks/useEmployees";

export default function ManageUsersScreen() {
	const authState = useAppSelector((state) => state.auth);
	const clientState = useAppSelector((state) => state.client);
	const employeeState = useAppSelector((state) => state.employee);
	const [showAddClientModal, setShowAddClientModal] = useState(false);
	const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
	const [showEmployeesToggle, setShowEmployeesToggle] = useState(false);
	const { auth } = useAuthentication();
	const { getClients } = useClients();
	const { getEmployees } = useEmployees();

	useEffect(() => {
		if (!clientState.loaded && authState.user?.type === "EMPLOYEE") {
			getClients();
		}
	}, [clientState.loaded, getClients]);

	useEffect(() => {
		if (!employeeState.loaded && authState.user?.type === "EMPLOYEE") {
			if (authState.user.employeeDetails?.isSuperAdmin) {
				getEmployees();
			}
		}
	}, [employeeState.loaded, getEmployees]);

	return (
		<Layout>
			<PageTitle title='Manage Users' />
			<div className='flex flex-col mt-10 p-8 bg-white w-10/12 rounded-lg shadow-lg'>
				<div className='flex justify-between mb-10'>
					{!showEmployeesToggle && (
						<button
							className='bg-supplyBlue text-white rounded-2xl w-36 py-2'
							onClick={() => setShowAddClientModal(true)}
						>
							Add Client
						</button>
					)}
					{auth.user?.employeeDetails?.isSuperAdmin && showEmployeesToggle && (
						<button
							className='bg-supplyBlue text-white rounded-2xl w-36 py-2'
							onClick={() => setShowAddEmployeeModal(true)}
						>
							Add Employee
						</button>
					)}
					{auth.user?.employeeDetails?.isSuperAdmin && (
						<div className='flex items-center space-x-4'>
							<p>Show Employees</p>
							<Switch
								toggle={showEmployeesToggle}
								onClick={() => setShowEmployeesToggle(!showEmployeesToggle)}
							/>
							<p>Show Clients</p>
						</div>
					)}
				</div>
				{!showEmployeesToggle && <ClientTable clients={clientState.clients} />}
				{showEmployeesToggle && (
					<EmployeeTable employees={employeeState.employees} />
				)}
			</div>
			{showAddClientModal && (
				<ClientModal setShowModal={setShowAddClientModal} type='NEW' />
			)}
			{showAddEmployeeModal && auth.user?.employeeDetails?.isSuperAdmin && (
				<EmployeeModal setShowModal={setShowAddEmployeeModal} type='NEW' />
			)}
		</Layout>
	);
}
