import { createSlice } from "@reduxjs/toolkit";
import { UserModel } from "../models/user.model";

interface LoginState {
	user: UserModel | null;
	signedIn: boolean | null;
}

const initialState: LoginState = {
	user: null,
	signedIn: null,
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
			state.signedIn = true;
		},
		clearUserData: (state, action) => {
			state.user = action.payload;
			state.signedIn = false;
		},
	},
});

export const { setUser, clearUserData } = authSlice.actions;
export default authSlice.reducer;
