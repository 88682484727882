import React from "react";
import Layout from "../../../app/components/layout/Layout";
import PageTitle from "../../../app/components/ui/PageTitle";
import Button from "../../../app/components/ui/Button";
import { useAppSelector } from "../../../app/hooks/useAppSelector";

export default function MyAccount() {
	const user = useAppSelector((state) => state.auth.user);
	const changeEmailHandler = () => {
		//
	};

	return (
		<Layout>
			<PageTitle title='My Account' />
			<div className='flex flex-col w-10/12 items-center justify-center p-10 mt-10 bg-white text-supplyBlue rounded-lg shadow-xl'>
				<div className='flex w-full border border-supplyBlue px-4 py-8'>
					<div className='flex flex-col w-6/12'>
						<h4 className='text-2xl font-bold mb-8'>Profile</h4>
						<p className='mb-2'>
							School/Company: {user?.clientDetails?.companyName}{" "}
						</p>
						<p className='mb-2'>
							Contact Name: {user?.clientDetails?.contactName}{" "}
						</p>
						<p className='mb-2'>Email Address: {user?.clientDetails?.email} </p>
						<p className='mb-2'>
							Contact Telephone: {user?.clientDetails?.telephone}{" "}
						</p>
					</div>
				</div>
				<div className='flex w-full justify-start space-x-8'>
					<Button
						label='Change Email Address'
						onClick={() => changeEmailHandler()}
						disabled={false}
						selected={false}
					/>
				</div>
			</div>
		</Layout>
	);
}
