import React, { ChangeEventHandler } from "react";

interface Props {
	label: string;
	showLabel: boolean;
	value: boolean;
	onChange: ChangeEventHandler<HTMLInputElement>;
}

function FormCheckField({ label, showLabel, value, onChange }: Props) {
	return (
		<div className='flex justify-between items-center w-10/12 mt-2'>
			{showLabel && <label className={`text-center `}>{label}</label>}
			<input
				className='h-10'
				type='checkbox'
				checked={value}
				onChange={onChange}
			/>
		</div>
	);
}

export default FormCheckField;
