import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { ProjectModel } from "../../projects/models/project.model";
import { RowModel } from "../models/row.model";
import DocumentRow from "./DocumentRow";

interface Props {
	showHeaders: boolean;
	label: string;
	bgColor: string;
	setShowAddDocumentRow: Function;
	rows: Array<RowModel>;
	project: ProjectModel;
	setProject: Function;
}

export default function DocumentRowContainer({
	showHeaders,
	label,
	bgColor,
	setShowAddDocumentRow,
	rows,
	project,
	setProject,
}: Props) {
	const authState = useAppSelector((state) => state.auth);
	return (
		<div className='flex w-full mb-10'>
			{/* Side Banner */}
			<div
				className={`flex flex-col w-[40px] min-h-[150px] items-center justify-center ${bgColor} text-white rounded-lg shadow-lg rotate-180`}
				style={{
					writingMode: "vertical-rl",
					boxShadow: "-4px -4px 2px rgb(0 0 0 / 0.2)",
				}}
			>
				<p>{label}</p>
			</div>
			{/* Main Section */}
			<div className='flex flex-col w-full justify-between ml-10 px-4'>
				{showHeaders && (
					<div className='flex'>
						<p className='w-5/12 font-semibold'>Upload Documents</p>
						<p className='w-7/12 font-semibold'>Additional Documents</p>
					</div>
				)}
				<div className='flex flex-col w-full grow'>
					{rows.map((row) => (
						<DocumentRow key={row.id} row={row} project={project} setProject={setProject} />
					))}
				</div>
				{authState.user?.type === "EMPLOYEE" && (
					<div className='flex justify-end'>
						<button className='font-semibold' onClick={() => setShowAddDocumentRow(true)}>
							Add Row+
						</button>
					</div>
				)}
			</div>
		</div>
	);
}
