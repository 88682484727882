import { ProjectModel } from "../../projects/models/project.model";

export const getNotificationFromType = (type: string, project: ProjectModel) => {
	switch (type) {
		case "UploadDoc":
			return {
				id: "",
				associatedProject: project.id,
				projectNumber: project.number,
				associatedClient: project.clientId,
				userType: "CLIENT",
				notificationType: type,
				notificationText: `You are required to upload a new document for Project #${project.number}`,
				read: false,
				dismissed: false,
			};

		case "CheckDoc":
			return {
				id: "",
				associatedProject: project.id,
				projectNumber: project.number,
				associatedClient: project.clientId,
				userType: "CLIENT",
				notificationType: type,
				notificationText: `A document requires your attention for Project #${project.number}`,
				read: false,
				dismissed: false,
			};

		case "UploadGoogle":
			return {
				id: "",
				associatedProject: project.id,
				projectNumber: project.number,
				associatedClient: project.clientId,
				userType: "CLIENT",
				notificationType: type,
				notificationText: `Please upload Google Maps Location/Images for Project #${project.number}`,
				read: false,
				dismissed: false,
			};

		case "UploadImg":
			return {
				id: "",
				associatedProject: project.id,
				projectNumber: project.number,
				associatedClient: project.clientId,
				userType: "CLIENT",
				notificationType: type,
				notificationText: `Please upload site images for Project #${project.number}`,
				read: false,
				dismissed: false,
			};

		case "ContactUs":
			return {
				id: "",
				associatedProject: project.id,
				projectNumber: project.number,
				associatedClient: project.clientId,
				userType: "CLIENT",
				notificationType: type,
				notificationText: `Please contact us regarding Project #${project.number}`,
				read: false,
				dismissed: false,
			};

		case "SignDoc":
			return {
				id: "",
				associatedProject: project.id,
				projectNumber: project.number,
				associatedClient: project.clientId,
				userType: "CLIENT",
				notificationType: type,
				notificationText: `Document requires signature on #${project.number}`,
				read: false,
				dismissed: false,
			};

		case "Review":
			return {
				id: "",
				associatedProject: project.id,
				projectNumber: project.number,
				associatedClient: project.clientId,
				userType: "CLIENT",
				notificationType: type,
				notificationText: `Please leave us a review for Project #${project.number}`,
				read: false,
				dismissed: false,
			};

		case "SiteAccess":
			return {
				id: "",
				associatedProject: project.id,
				projectNumber: project.number,
				associatedClient: project.clientId,
				userType: "CLIENT",
				notificationType: type,
				notificationText: `Please upload site access for Project #${project.number}`,
				read: false,
				dismissed: false,
			};

		default:
			return {
				id: "",
				associatedProject: "",
				projectNumber: "",
				associatedClient: "",
				userType: "",
				notificationType: "",
				notificationText: "",
				read: false,
				dismissed: false,
			};
	}
};
