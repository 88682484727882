import { FormEvent, useState } from "react";
import Layout from "../../../app/components/layout/Layout";
import { MdOutlineAccountCircle } from "react-icons/md";
import LoadingIcon from "../../../app/components/ui/LoadingIcon";
import useAuthentication from "../hooks/useAuthentication";
import { Link } from "react-router-dom";

export default function LoginScreen() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [rememberMe, setRememberMe] = useState(false);
	const { isLoading, signInUser } = useAuthentication();
	const loading = isLoading;
	const loginSubmitHandler = async (e: FormEvent) => {
		e.preventDefault();
		await signInUser({ email, password, rememberMe });
	};

	return (
		<Layout>
			<div className='flex flex-col text-white'>
				<h3 className='text-center text-3xl font-bold'>Log In</h3>
				<form
					className='flex flex-col items-center h-[24rem] w-[30rem] bg-gradient-to-b from-supplyPink to-supplyBlue rounded-xl mt-4 py-4 shadow-2xl'
					onSubmit={(e) => loginSubmitHandler(e)}
				>
					<MdOutlineAccountCircle size={80} className='mt-3 mb-12' />
					<input
						type='text'
						className='w-8/12 px-2 h-10 rounded-lg bg-supplyBlue placeholder-white'
						placeholder='Username'
						autoComplete='username'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<input
						type='password'
						className='w-8/12 px-2 h-10 rounded-lg bg-supplyBlue placeholder-white mt-6'
						placeholder='Password'
						autoComplete='current-password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<div className='flex items-center mt-4 w-8/12'>
						<input
							type='checkbox'
							id='rememberCheck'
							className='accent-supplyPink h-5 w-5 content-[X]'
							checked={rememberMe}
							onChange={(e) => setRememberMe(e.target.checked)}
						/>
						<label htmlFor='rememberCheck' className='ml-2 select-none'>
							Remember me
						</label>
					</div>
					<button
						className='mt-2 w-[200px] h-10 px-4 py-2 bg-supplyPink disabled:bg-slate-200 text-white rounded-lg'
						type='submit'
						disabled={loading}
					>
						{loading ? <LoadingIcon /> : "Login"}
					</button>
					<Link to='/forgot-password' className='mt-2 text-blue-500'>
						Forgot Password?
					</Link>
				</form>
			</div>
			<div className='flex w-full justify-end mt-10'>
				<img
					className=''
					src='/euInnovationLogo.png'
					width={500}
					alt='European Union European Regional Development Fund - Growth Through Innovation Fund'
				/>
			</div>
		</Layout>
	);
}
