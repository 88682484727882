import React, { ChangeEventHandler } from "react";

interface Props {
	label: string;
	name: string;
	value: string;
	onChange: ChangeEventHandler<HTMLInputElement>;
}

export default function FormTextField({ label, name, value, onChange }: Props) {
	return (
		<div className='flex flex-col w-full mt-2 mb-4'>
			<label htmlFor={name} className='text-supplyBlue mb-2 select-none'>
				{label}
			</label>
			<input
				className='w-full p-2 h-10 bg-slate-300 text-supplyBlue border'
				id={name}
				name={name}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
}
