import { MouseEventHandler, ReactNode } from "react";

function ModalTemplate({
	children,
	title,
	onCancelHandler,
	onSaveHandler,
	buttonsDisabled,
	hideButton,
}: {
	children: ReactNode;
	title: string;
	onCancelHandler: MouseEventHandler;
	onSaveHandler: MouseEventHandler;
	buttonsDisabled: boolean;
	hideButton?: boolean;
}) {
	return (
		<div className='flex bg-gray-500/75 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none '>
			<div className='relative my-6 mx-auto sm:w-[30rem] md:w-[40rem] xl:w-[48rem] max-h-screen'>
				<div className='flex flex-col border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none'>
					<div className='flex w-full items-center justify-center space-x-10 pt-12 rounded-t '>
						<h3 className='px-8 py-1 text-lg text-white bg-supplyPink rounded-xl shadow-xl'>
							{title}
						</h3>
					</div>
					<div className='flex flex-col p-2'>{children}</div>
					{!hideButton && (
						<div className='flex space-x-8 justify-end px-10 py-4'>
							<button
								className='bg-supplyBlue w-28 text-white px-4 py-2 rounded-2xl disabled:bg-slate-100'
								onClick={onCancelHandler}
								disabled={buttonsDisabled}
							>
								Cancel
							</button>

							<button
								className='bg-supplyBlue w-28 text-white px-4 py-2 rounded-2xl disabled:bg-slate-100'
								onClick={onSaveHandler}
								disabled={buttonsDisabled}
							>
								Save
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default ModalTemplate;
