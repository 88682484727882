import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

export default function useFirebaseStorage() {
	const storage = getStorage();
	const timestamp = Date.now();

	const uploadDocument = async (file: File, index: number) => {
		const storageRef = ref(storage, `documents/${timestamp}${index}`);
		const uploadTask = await uploadBytes(storageRef, file);
		const fileURL = await getDownloadURL(uploadTask.ref);
		return fileURL;
	};

	return { uploadDocument };
}
