import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/store/auth.slice";
import clientReducer from "../features/users/store/client.slice";
import employeeReducer from "../features/users/store/employee.slice";
import notificationReducer from "../features/notifications/store/notification.slice";
import projectReducer from "../features/projects/store/project.slice";

const store = configureStore({
	reducer: {
		auth: authReducer,
		client: clientReducer,
		employee: employeeReducer,
		notification: notificationReducer,
		project: projectReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
