import axios from "axios";
import { ProjectModel } from "../models/project.model";
import { axiosErrorHandler, config, projectRoute } from "../../../app/api";

export const getProjectsApi = async (userToken: string) => {
	try {
		const { data } = await axios.get<Array<ProjectModel>>(
			projectRoute,
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};

export const getProjectByNumberApi = async (
	projectNumber: string,
	userToken: string
) => {
	try {
		const { data } = await axios.get<ProjectModel>(
			`${projectRoute}/number?number=${projectNumber}`,
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
