import axios from "axios";
import { ProjectModel } from "../models/project.model";
import { axiosErrorHandler, config, projectRoute } from "../../../app/api";

export const updateProjectApi = async (
	project: ProjectModel,
	userToken: string
) => {
	try {
		const { data } = await axios.put<ProjectModel>(
			projectRoute,
			{ project },
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
