import axios from "axios";
import { DocumentModel } from "../models/document.model";
import {
	axiosErrorHandler,
	configDelete,
	documentRoute,
} from "../../../app/api";

export const deleteDocumentApi = async (
	document: DocumentModel,
	userToken: string
) => {
	try {
		const { data } = await axios.delete<DocumentModel>(
			`${documentRoute}/documents`,
			configDelete(userToken, document)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
