import { useEffect, useState } from "react";
import ModalTemplate from "../../../app/components/ui/modals/ModalTemplate";
import NotificationOption from "./NotificationOption";
import { NotificationModel } from "../models/notification.model";
import { ProjectModel } from "../../projects/models/project.model";
import { toast } from "react-toastify";
import useNotifications from "../hooks/useNotifications";

interface Props {
	project: ProjectModel;
	setShowModal: Function;
}
export default function NotificationModal({ project, setShowModal }: Props) {
	const [notification, setNotification] = useState<NotificationModel>({
		id: "",
		associatedProject: project.id,
		projectNumber: project.number,
		associatedClient: project.clientId,
		userType: "CLIENT",
		notificationType: "",
		notificationText: "",
		read: false,
		dismissed: false,
	});

	const { isLoading, actionSuccessful, createNotification } = useNotifications();

	const onCancelHandler = () => {
		setShowModal(false);
	};

	const onSaveHandler = async () => {
		if (notification.notificationType === "") {
			toast.warning("Please select a notification type");
			return;
		}
		await createNotification(notification);
	};

	useEffect(() => {
		if (actionSuccessful) {
			setShowModal(false);
		}
	}, [actionSuccessful, setShowModal]);

	return (
		<ModalTemplate
			title='Notification Request'
			onCancelHandler={onCancelHandler}
			onSaveHandler={onSaveHandler}
			buttonsDisabled={isLoading}
		>
			<div className='flex flex-col w-full mt-2'>
				<p className='text-supplyPink text-center'>
					Send a request to your customer from the following options:
				</p>
				<div className='flex flex-wrap justify-between mt-8 px-8'>
					<NotificationOption
						name='UploadDoc'
						label='Upload a document'
						checked={notification.notificationType === "UploadDoc"}
						onClick={() =>
							setNotification({
								...notification,
								notificationType: "UploadDoc",
								notificationText: `You are required to upload a new document for Project #${project.number}`,
							})
						}
					/>
					<NotificationOption
						name='UploadGoogle'
						label='Upload Google Maps Location/Images'
						checked={notification.notificationType === "UploadGoogle"}
						onClick={() =>
							setNotification({
								...notification,
								notificationType: "UploadGoogle",
								notificationText: `Please upload Google Maps Location/Images for Project #${project.number}`,
							})
						}
					/>
					<NotificationOption
						name='CheckDoc'
						label='Check a document'
						checked={notification.notificationType === "CheckDoc"}
						onClick={() =>
							setNotification({
								...notification,
								notificationType: "CheckDoc",
								notificationText: `A document requires your attention for Project #${project.number}`,
							})
						}
					/>
					<NotificationOption
						name='UploadImg'
						label='Upload Site Images'
						checked={notification.notificationType === "UploadImg"}
						onClick={() =>
							setNotification({
								...notification,
								notificationType: "UploadImg",
								notificationText: `Please upload site images for Project #${project.number}`,
							})
						}
					/>
					<NotificationOption
						name='ContactUs'
						label='Request Customer to Contact'
						checked={notification.notificationType === "ContactUs"}
						onClick={() =>
							setNotification({
								...notification,
								notificationType: "ContactUs",
								notificationText: `Please contact us regarding Project #${project.number}`,
							})
						}
					/>
					<NotificationOption
						name='SignDoc'
						label='Sign a Document'
						checked={notification.notificationType === "SignDoc"}
						onClick={() =>
							setNotification({
								...notification,
								notificationType: "SignDoc",
								notificationText: `Document requires signature on #${project.number}`,
							})
						}
					/>
					<NotificationOption
						name='Review'
						label='Leave us a Review'
						checked={notification.notificationType === "Review"}
						onClick={() =>
							setNotification({
								...notification,
								notificationType: "Review",
								notificationText: `Please leave us a review for Project #${project.number}`,
							})
						}
					/>
					<NotificationOption
						name='SiteAccess'
						label='Upload Site Access Info'
						checked={notification.notificationType === "SiteAccess"}
						onClick={() =>
							setNotification({
								...notification,
								notificationType: "SiteAccess",
								notificationText: `Please upload site access for Project #${project.number}`,
							})
						}
					/>
				</div>
			</div>
		</ModalTemplate>
	);
}
