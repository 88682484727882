import { MouseEventHandler } from "react";

interface Props {
	toggle: boolean;
	onClick: MouseEventHandler<HTMLDivElement>;
}

function Switch({ toggle, onClick }: Props) {
	const toggleClass = " transform translate-x-5 bg-supplyPink";
	return (
		<>
			<div className='flex flex-col justify-center items-center '>
				{/*   Switch Container */}
				<div
					className='md:w-14 md:h-7 w-12 h-6 flex items-center bg-supplyBlue rounded-full p-1 cursor-pointer'
					onClick={onClick}
				>
					{/* Switch */}
					<div
						className={
							"md:w-6 md:h-6 h-5 w-5 rounded-full shadow-md transform duration-300 ease-in-out" +
							(toggle ? " bg-supplyPink" : toggleClass)
						}
					></div>
				</div>
			</div>
		</>
	);
}

export default Switch;
