import React from "react";
import { JsxElement } from "typescript";

interface Column {
	title: string;
	className?: string;
}

interface Field {
	className?: string;
	value: string | JSX.Element | undefined;
}

interface Row {
	fields: Array<Field>;
}

function Table({
	columns,
	rows,
}: {
	columns: Array<Column>;
	rows: Array<Row>;
}) {
	return (
		<div className='flex flex-col w-full'>
			<table className='table-auto border w-full mx-auto'>
				<thead>
					<tr className='bg-slate-200 text-youthsGrey text-xs'>
						<th>#</th>
						{columns.map((column, index) => (
							<th key={index} className={column.className}>
								{column.title}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rows?.map((row, index) => (
						<tr
							key={index}
							className={
								index % 2
									? "bg-slate-100 text-center text-xs h-10"
									: "bg-slate-300 text-center text-xs h-10"
							}
						>
							<td>{index + 1}</td>
							{row.fields.map((field, index) => (
								<td key={index} className={field.className}>
									{field.value}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			{rows.length === 0 && (
				<p className='text-center p-10'>No results found</p>
			)}
		</div>
	);
}

export default Table;
