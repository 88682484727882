import axios from "axios";

export const authRoute = `${process.env.REACT_APP_BACKEND}/auth`;
export const clientRoute = `${process.env.REACT_APP_BACKEND}/client`;
export const documentRoute = `${process.env.REACT_APP_BACKEND}/document`;
export const employeeRoute = `${process.env.REACT_APP_BACKEND}/employee`;
export const notificationRoute = `${process.env.REACT_APP_BACKEND}/notification`;
export const projectRoute = `${process.env.REACT_APP_BACKEND}/project`;
export const publicRoute = `${process.env.REACT_APP_BACKEND}/public`;

export const config = (userToken: string) => {
	return {
		headers: {
			Authorization: "Bearer " + userToken,
		},
	};
};

export const configDelete = (userToken: string, dataPayload: any) => {
	return {
		headers: {
			Authorization: "Bearer " + userToken,
		},
		data: {
			dataPayload,
		},
	};
};

export const axiosErrorHandler = (error: any) => {
	if (axios.isAxiosError(error as Error)) {
		console.log("error message: ", error);
		throw new Error(error.response.data);
	} else {
		console.log("unexpected error: ", error.message);
		throw new Error(error);
	}
};
