import axios from "axios";
import { axiosErrorHandler, config, documentRoute } from "../../../app/api";
import { RowModel } from "../models/row.model";
import { DocumentModel } from "../models/document.model";

export const createDocumentApi = async (
	documents: Array<DocumentModel>,
	userToken: string
) => {
	try {
		const { data } = await axios.post(
			`${documentRoute}/documents`,
			{ documents },
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};

export const createRowApi = async (row: RowModel, userToken: string) => {
	try {
		const { data } = await axios.post(
			`${documentRoute}/row`,
			{ row },
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
