import React, { ChangeEventHandler } from "react";

interface Props {
	name: string;
	label: string;
	checked: boolean;
	onClick: ChangeEventHandler<HTMLInputElement>;
}

export default function NotificationOption({ name, label, checked, onClick }: Props) {
	return (
		<div className='flex w-6/12 items-center mb-4'>
			<input type='checkbox' checked={checked} onChange={onClick} id={name} name={name} />
			<label className='text-supplyPink ml-4 select-none' htmlFor={name}>
				{label}
			</label>
		</div>
	);
}
