import React, { useEffect, useState } from "react";
import ModalTemplate from "../../../app/components/ui/modals/ModalTemplate";
import { EmployeeModel } from "../models/employee.model";
import FormTextField from "../../../app/components/ui/forms/FormTextField";
import FormCheckField from "../../../app/components/ui/forms/FormCheckField";
import useEmployees from "../hooks/useEmployees";

interface Props {
	employeeToEdit?: EmployeeModel | null;
	setShowModal: Function;
	type: string;
}
export default function EmployeeModal({
	employeeToEdit,
	setShowModal,
	type,
}: Props) {
	const [employee, setEmployee] = useState<EmployeeModel>({
		id: "",
		associatedUser: "",
		employeeName: "",
		email: "",
		isSuperAdmin: false,
		userDetailsEmployee: {
			id: "",
			type: "",
			firebaseId: "",
			employeeDetails: null,
			clientDetails: null,
		},
	});

	const { isLoading, actionSuccessful, createEmployee, updateEmployee } =
		useEmployees();

	const onCancelHandler = () => {
		setShowModal(false);
	};

	const onSaveHandler = async () => {
		if (type === "NEW") {
			await createEmployee(employee);
		} else if (type === "EDIT") {
			await updateEmployee(employee);
		}
	};

	useEffect(() => {
		if (actionSuccessful) {
			setShowModal(false);
		}
	}, [actionSuccessful]);

	useEffect(() => {
		if (type === "EDIT" && employeeToEdit) {
			setEmployee({
				id: employeeToEdit.id,
				associatedUser: employeeToEdit.associatedUser,
				employeeName: employeeToEdit.employeeName,
				email: employeeToEdit.email,
				isSuperAdmin: employeeToEdit.isSuperAdmin,
				userDetailsEmployee: employeeToEdit.userDetailsEmployee,
			});
		}
	}, [type, employeeToEdit]);

	return (
		<ModalTemplate
			title='Add New Employee'
			onCancelHandler={onCancelHandler}
			onSaveHandler={onSaveHandler}
			buttonsDisabled={isLoading}
		>
			<div className='flex justify-between px-8 py-4'>
				{/* Left */}
				<div className='flex flex-col w-5/12'>
					<FormTextField
						label='Employee Name'
						name='employeeNameInput'
						value={employee.employeeName}
						onChange={(e) =>
							setEmployee({ ...employee, employeeName: e.target.value })
						}
					/>
					<FormCheckField
						label='Super Admin Access'
						showLabel={true}
						value={employee.isSuperAdmin}
						onChange={(e) =>
							setEmployee({ ...employee, isSuperAdmin: e.target.checked })
						}
					/>
				</div>
				{/* Right */}
				<div className='flex flex-col w-5/12'>
					<FormTextField
						label='Email Address'
						name='emailAddressInput'
						value={employee.email}
						onChange={(e) =>
							setEmployee({ ...employee, email: e.target.value })
						}
					/>
				</div>
			</div>
		</ModalTemplate>
	);
}
