import { useEffect, useState } from "react";
import ModalTemplate from "../../../app/components/ui/modals/ModalTemplate";
import { ProjectModel } from "../models/project.model";
import FormSelectField from "../../../app/components/ui/forms/FormSelectField";
import { AddressModel } from "../../../app/models/address.model";
import FormAddressField from "../../../app/components/ui/forms/FormAddressField";
import FormTextField from "../../../app/components/ui/forms/FormTextField";
import FormTextAreaField from "../../../app/components/ui/forms/FormTextAreaField";
import useProjects from "../hooks/useProjects";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { toast } from "react-toastify";

interface Props {
	setShowModal: Function;
}

export default function ProjectModal({ setShowModal }: Props) {
	const clientState = useAppSelector((state) => state.client);
	const [project, setProject] = useState<ProjectModel>({
		id: "",
		number: "",
		clientId: "",
		documentRows: [],
		projectName: "",
		projectDetails: "",
		projectStatus: "Quote",
	});
	const [address, setAddress] = useState<AddressModel>({
		addrOne: "",
		addrTwo: "",
		city: "",
		postcode: "",
	});

	const { isLoading, actionSuccessful, createProject } = useProjects();

	const onCancelHandler = () => {
		setShowModal(false);
	};

	const onSaveHandler = async () => {
		if (project.clientId === "") {
			toast.warning("Please select a client");
			return;
		}
		await createProject(project, address);
	};

	useEffect(() => {
		if (actionSuccessful) {
			setShowModal(false);
		}
	}, [actionSuccessful, setShowModal]);

	return (
		<ModalTemplate
			title='Add New Project'
			onCancelHandler={() => onCancelHandler()}
			onSaveHandler={() => onSaveHandler()}
			buttonsDisabled={isLoading}
		>
			<div className='flex justify-between px-8 py-4'>
				{/* Left */}
				<div className='flex flex-col w-5/12'>
					<FormSelectField
						label='Select Client *'
						name='clientSelect'
						value={project?.clientId || ""}
						onChange={(e) =>
							setProject({ ...project, clientId: e.target.value })
						}
						options={clientState.clients.map((client) => ({
							label: client.companyName,
							value: client.id,
						}))}
					/>
					<FormAddressField
						label='Delivery Address *'
						address={address}
						setAddress={setAddress}
					/>
				</div>
				{/* Right */}
				<div className='flex flex-col w-5/12'>
					<FormTextField
						label='Project Name *'
						name='projectNameInput'
						value={project.projectName}
						onChange={(e) =>
							setProject({ ...project, projectName: e.target.value })
						}
					/>
					<FormTextAreaField
						label='Project Details *'
						name='projectDetailsInput'
						value={project.projectDetails}
						onChange={(e) =>
							setProject({ ...project, projectDetails: e.target.value })
						}
					/>
				</div>
			</div>
		</ModalTemplate>
	);
}
