import axios from "axios";
import { DocumentModel } from "../models/document.model";
import { axiosErrorHandler, config, documentRoute } from "../../../app/api";

export const updateDocumentApi = async (
	document: DocumentModel,
	userToken: string
) => {
	try {
		const { data } = await axios.put<DocumentModel>(
			`${documentRoute}/documents`,
			{ document },
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
