import axios from "axios";
import { ProjectModel } from "../models/project.model";
import {
	axiosErrorHandler,
	configDelete,
	projectRoute,
} from "../../../app/api";

export const deleteProjectApi = async (
	project: ProjectModel,
	userToken: string
) => {
	try {
		const { data } = await axios.delete<ProjectModel>(
			projectRoute,
			configDelete(userToken, project)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
