import { createSlice } from "@reduxjs/toolkit";
import { NotificationModel } from "../models/notification.model";
import { clearUserData } from "../../auth/store/auth.slice";

interface NotificationState {
	notifications: Array<NotificationModel> | null;
	loaded: boolean;
}

const initialState: NotificationState = {
	notifications: null,
	loaded: false,
};

export const notificationSlice = createSlice({
	name: "notification",
	initialState,
	reducers: {
		setNotifications: (state, action) => {
			state.notifications = action.payload;
			state.loaded = true;
		},
		updateNotifications: (state, action) => {
			const newState = state.notifications
				? state.notifications.filter((notification) => notification.id !== action.payload.id)
				: [];
			state.notifications = [...newState, action.payload];
			state.loaded = true;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(clearUserData, () => {
			return initialState;
		});
	},
});

export const { setNotifications, updateNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
