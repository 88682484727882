import axios from "axios";
import { NotificationModel } from "../models/notification.model";
import { axiosErrorHandler, config, notificationRoute } from "../../../app/api";

export const markNotificationReadApi = async (
	notification: NotificationModel,
	userToken: string
) => {
	try {
		const { data } = await axios.put<NotificationModel>(
			`${notificationRoute}/read`,
			{ notification },
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
