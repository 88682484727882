import { FormEvent, useEffect, useState } from "react";
import Layout from "../../../app/components/layout/Layout";
import LoadingSpinner from "../../../app/components/ui/LoadingSpinner";
import { MdOutlineAccountCircle } from "react-icons/md";
import useInvite from "../hooks/useInvite";
import { useParams } from "react-router-dom";
import LoadingIcon from "../../../app/components/ui/LoadingIcon";
import { toast } from "react-toastify";

type InviteParams = {
	inviteId: string;
};

export default function InviteScreen() {
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [rememberMe, setRememberMe] = useState(false);

	const { inviteId } = useParams<InviteParams>();
	const { inviteLoaded, isLoading, invite, getInvite, completeInvite } =
		useInvite();

	const completeInviteHandler = async (e: FormEvent) => {
		e.preventDefault();
		if (password.trim() === "") {
			toast.warning("Please provide password");
			return;
		}
		if (confirmPassword.trim() === "") {
			toast.warning("Please confirm password");
			return;
		}
		if (password.length < 6) {
			toast.warning("Password is too short. Must be 6 characters or more.");
			return;
		}
		if (password !== confirmPassword) {
			toast.warning("Passwords do not match");
			return;
		}
		await completeInvite(password, rememberMe);
	};

	useEffect(() => {
		if (!inviteLoaded) {
			getInvite(inviteId);
		}
	}, [inviteLoaded, inviteId]);

	return (
		<Layout>
			{inviteLoaded && (
				<div className='flex flex-col items-center text-white'>
					<h3 className='text-center text-3xl font-bold'>Invite</h3>
					<p className='text-center textxl w-8/12 mt-2'>
						You have been invited to the supplying direct services project
						portal.
					</p>
					<form
						className='flex flex-col items-center h-[24rem] w-[30rem] bg-gradient-to-b from-supplyPink to-supplyBlue rounded-xl mt-2 py-4 shadow-2xl'
						onSubmit={(e) => completeInviteHandler(e)}
					>
						<MdOutlineAccountCircle size={80} className='mt-3 mb-8' />
						<input
							type='text'
							className='w-8/12 px-2 h-10 rounded-lg bg-slate-600 placeholder-white outline-none'
							value={invite!.email}
							readOnly
						/>
						<input
							type='password'
							className='w-8/12 px-2 h-10 rounded-lg bg-supplyBlue placeholder-white mt-6'
							placeholder='Create Password'
							autoComplete='new-password'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<input
							type='password'
							className='w-8/12 px-2 h-10 rounded-lg bg-supplyBlue placeholder-white mt-6'
							placeholder='Confirm Password'
							autoComplete='new-password'
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
						<div className='flex items-center mt-4 w-8/12'>
							<input
								type='checkbox'
								id='rememberCheck'
								className='accent-supplyPink h-5 w-5 content-[X]'
								checked={rememberMe}
								onChange={(e) => setRememberMe(e.target.checked)}
							/>
							<label htmlFor='rememberCheck' className='ml-2 select-none'>
								Remember me
							</label>
						</div>
						<button
							className='mt-4 w-[200px] h-10 px-4 py-2 bg-supplyPink disabled:bg-slate-200 text-white rounded-lg'
							type='submit'
							disabled={isLoading}
						>
							{isLoading ? <LoadingIcon /> : "Create Account"}
						</button>
					</form>
				</div>
			)}
			{!inviteLoaded && (
				<div className='w-6/12 py-10 bg-white rounded-xl shadow-2xl'>
					<LoadingSpinner message='Loading invite' />
				</div>
			)}
		</Layout>
	);
}
