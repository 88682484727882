import React, { useState } from "react";
import LoadingIcon from "../../../app/components/ui/LoadingIcon";
import Layout from "../../../app/components/layout/Layout";
import { sendPasswordResetEmail } from "firebase/auth";
import { firebaseAuth } from "../../../app/firebase";
import { toast } from "react-toastify";

export default function ForgotPassword() {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const forgotPasswordHandler = () => {
		if (email.trim() === "") {
			toast.warning("Please enter email address");
			return;
		}
		setLoading(true);
		sendPasswordResetEmail(firebaseAuth, email)
			.then(() => {
				setSuccess(true);
				setLoading(false);
			})
			.catch((error) => {
				toast.error(`Error resetting password ${error.code}`);
				setLoading(false);
			});
	};
	return (
		<Layout>
			{!success && (
				<div className='flex flex-col items-center justify-around h-[14rem] w-[30rem] bg-gradient-to-b from-supplyPink to-supplyBlue rounded-xl mt-40 py-4 shadow-2xl text-white'>
					<input
						type='email'
						className='w-8/12 px-2 h-10 rounded-lg bg-supplyBlue placeholder-white'
						placeholder='Enter your email address'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<button
						className='mt-2 w-[200px] h-10 px-4 py-2 bg-supplyPink disabled:bg-slate-200 text-white rounded-lg'
						onClick={() => forgotPasswordHandler()}
						disabled={loading}
					>
						{loading ? <LoadingIcon /> : "Reset Password"}
					</button>
					<p className='mt-4'>
						Return to{" "}
						<a href='/' className='text-blue-500 underline'>
							Sign In
						</a>
					</p>
				</div>
			)}

			{success && (
				<div className='flex flex-col px-8 items-center justify-around h-[10rem] w-[30rem] bg-gradient-to-b from-supplyPink to-supplyBlue rounded-xl mt-40 py-4 shadow-2xl text-white'>
					<p className='text-center'>
						Success - if the email address you entered is valid you will receive instructions on how
						to reset your password by email.
					</p>
					<p className='mt-4'>
						Return to{" "}
						<a href='/' className='text-blue-500 underline'>
							Sign In
						</a>
					</p>
				</div>
			)}
		</Layout>
	);
}
