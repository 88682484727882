import axios from "axios";
import { axiosErrorHandler, config, notificationRoute } from "../../../app/api";
import { NotificationModel } from "../models/notification.model";

export const createNotificationApi = async (notification: NotificationModel, userToken: string) => {
	try {
		const { data } = await axios.post<NotificationModel>(
			notificationRoute,
			{ notification },
			config(userToken)
		);
		return data;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
