import { useParams } from "react-router-dom";
import Layout from "../../../app/components/layout/Layout";
import PageTitle from "../../../app/components/ui/PageTitle";
import { useEffect, useState } from "react";
import { ProjectModel } from "../models/project.model";
import useProjects from "../hooks/useProjects";
import useClients from "../../users/hooks/useClients";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import LoadingSpinner from "../../../app/components/ui/LoadingSpinner";
import { ClientModel } from "../../users/models/client.model";
import ProjectDetailProperty from "../../users/components/ProjectDetailProperty";
import DocumentModal from "../../documents/components/DocumentModal";
import DocumentRowContainer from "../../documents/components/DocumentRowContainer";

type URLParam = {
	projectNumber: string;
};

export default function ProjectDetail() {
	const clientState = useAppSelector((state) => state.client);
	const [showAddDocumentRow, setShowAddDocumentRow] = useState(false);
	const [project, setProject] = useState<ProjectModel | null>(null);
	const [client, setClient] = useState<ClientModel | null>(null);
	const { projectNumber } = useParams<URLParam>();
	const { getProjectByNumber } = useProjects();
	const { getClients, clientFromId } = useClients();

	useEffect(() => {
		if (!project && projectNumber && clientState.loaded) {
			const getProjectHandler = async () => {
				const getProject = await getProjectByNumber(projectNumber);
				if (getProject) {
					const projectClient = clientFromId(getProject?.clientId, clientState.clients);
					setClient(projectClient);
				}
				setProject(getProject);
			};
			getProjectHandler();
		}
	}, [project, projectNumber, clientState, clientFromId, getProjectByNumber]);

	useEffect(() => {
		if (!clientState.loaded) {
			getClients();
		}
	}, [clientState.loaded, getClients]);

	return (
		<Layout>
			<PageTitle title={`Project Details #${projectNumber}`} />
			{(!project || !client) && <LoadingSpinner message='Loading project' />}
			{project && client && (
				<div className='flex w-10/12 bg-white p-6 my-20 shadow-lg'>
					<div className='flex flex-col space-y-4 w-4/12 pr-2'>
						<ProjectDetailProperty heading='Project Name: ' value={project.projectName} />
						<ProjectDetailProperty heading='Contact Name: ' value={client.contactName} />
						<ProjectDetailProperty heading='Telephone: ' value={client.telephone} />
						<ProjectDetailProperty heading='Email: ' value={client.email} />
					</div>
					<div className='flex w-4/12 pr-2'>
						<p className='font-semibold'>Delivery Address: </p>
						<div className='flex flex-col ml-2'>
							<p>{project.deliveryAddress?.addrOne}</p>
							<p>{project.deliveryAddress?.addrTwo}</p>
							<p>{project.deliveryAddress?.city}</p>
							<p>{project.deliveryAddress?.postcode}</p>
						</div>
					</div>
					<div className='flex w-4/12 pr-2'>
						<p className='font-semibold w-[200px]'>Project Details: </p>
						<p>{project.projectDetails}</p>
					</div>
				</div>
			)}
			{project && client && (
				<DocumentRowContainer
					showHeaders={true}
					label='Enquiries'
					bgColor='bg-supplyPink'
					setShowAddDocumentRow={setShowAddDocumentRow}
					rows={
						project.documentRows
							? project?.documentRows?.filter((row) => row.section === "Enquiries")
							: []
					}
					project={project}
					setProject={setProject}
				/>
			)}
			{project &&
				client &&
				project?.documentRows?.filter((row) => row.section === "WIP").length > 0 && (
					<DocumentRowContainer
						showHeaders={false}
						label='In Process'
						bgColor='bg-supplyBlue'
						setShowAddDocumentRow={setShowAddDocumentRow}
						rows={project?.documentRows?.filter((row) => row.section === "WIP")}
						project={project}
						setProject={setProject}
					/>
				)}
			{project &&
				client &&
				project?.documentRows?.filter((row) => row.section === "Completed").length > 0 && (
					<DocumentRowContainer
						showHeaders={false}
						label='Complete'
						bgColor='bg-supplyPink'
						setShowAddDocumentRow={setShowAddDocumentRow}
						rows={project?.documentRows?.filter((row) => row.section === "Completed")}
						project={project}
						setProject={setProject}
					/>
				)}
			{showAddDocumentRow && project && (
				<DocumentModal
					setShowModal={setShowAddDocumentRow}
					project={project}
					setProject={setProject}
				/>
			)}
		</Layout>
	);
}
