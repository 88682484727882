import { useState } from "react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import useProjects from "../hooks/useProjects";
import { ProjectModel } from "../models/project.model";
import LoadingIcon from "../../../app/components/ui/LoadingIcon";
import { useAppSelector } from "../../../app/hooks/useAppSelector";
import { Link } from "react-router-dom";

export default function ProjectCard({ project }: { project: ProjectModel }) {
	const authState = useAppSelector((state) => state.auth);
	const clientState = useAppSelector((state) => state.client);
	const [showCardMenu, setShowCardMenu] = useState(false);
	const { isLoading, updateProjectStatus, deleteProject } = useProjects();

	const clientFromId = (clientId: string) => {
		const client = clientState.clients.filter(
			(client) => client.id === clientId
		)[0];
		return client?.companyName;
	};

	const deleteProjectHandler = async () => {
		await deleteProject(project);
	};

	return (
		<div className='flex flex-col relative justify-between w-full h-64 bg-white shadow-xl text-supplyBlue mb-4'>
			<Link to={`/project/${project.number}`} className='flex flex-col grow'>
				<div className='flex flex-col p-6 text-xl'>
					<p>{project.projectName}</p>
					<p>{clientFromId(project.clientId)}</p>
				</div>
			</Link>
			<div className='flex justify-between items-center w-full bg-supplyBlue text-white py-1 px-2 text-sm'>
				<p>
					{project.projectStatus === "Quote"
						? "Project"
						: project.projectStatus}{" "}
					#{project.number}
				</p>
				{authState?.user?.type === "EMPLOYEE" && (
					<button onClick={() => setShowCardMenu(!showCardMenu)}>
						<HiOutlineDotsHorizontal size={25} />
					</button>
				)}
			</div>
			{showCardMenu && (
				<div className='flex flex-col justify-between absolute bottom-0 right-0 h-16 w-[8.5rem] translate-y-16 border-t-white border-t bg-supplyBlue text-white text-xs p-2 z-10'>
					{project.projectStatus !== "Quote" && (
						<button
							className='text-left disabled:hidden'
							onClick={() => updateProjectStatus(project, "Quote")}
							disabled={isLoading}
						>
							Move to projects
						</button>
					)}
					{project.projectStatus !== "Pending" && (
						<button
							className='text-left disabled:hidden'
							onClick={() => updateProjectStatus(project, "Pending")}
							disabled={isLoading}
						>
							Move to pending
						</button>
					)}
					{project.projectStatus !== "Completed" && (
						<button
							className='text-left disabled:hidden'
							onClick={() => updateProjectStatus(project, "Completed")}
							disabled={isLoading}
						>
							Move to completed
						</button>
					)}
					<button
						className='text-left disabled:hidden'
						onClick={() => deleteProjectHandler()}
						disabled={isLoading}
					>
						Delete
					</button>
					{isLoading && <LoadingIcon />}
				</div>
			)}
		</div>
	);
}
