import axios from "axios";
import { axiosErrorHandler, publicRoute } from "../../../app/api";
import { InviteModel } from "../models/invite.model";

export const getInviteApi = async (inviteId: string | undefined) => {
	try {
		const response = await axios.get<InviteModel>(`${publicRoute}/invite/`, {
			params: {
				inviteId: inviteId,
			},
		});
		const invite = response.data;
		return invite;
	} catch (error) {
		return axiosErrorHandler(error);
	}
};
