import { useEffect, useState } from "react";
import Layout from "../../../app/components/layout/Layout";
import CardStack from "../components/CardStack";
import ProjectModal from "../components/ProjectModal";
import PageTitle from "../../../app/components/ui/PageTitle";
import useClients from "../../users/hooks/useClients";
import useProjects from "../hooks/useProjects";
import { useAppSelector } from "../../../app/hooks/useAppSelector";

export default function Dashboard() {
	const authState = useAppSelector((state) => state.auth);
	const clientState = useAppSelector((state) => state.client);
	const [showAddProjectModal, setShowAddProjectModal] = useState(false);
	const { getClients } = useClients();
	const { projectState, getProjects } = useProjects();

	useEffect(() => {
		if (!projectState.loaded) {
			getProjects();
		}
	}, [projectState.loaded, getProjects]);

	useEffect(() => {
		if (!clientState.loaded) {
			getClients();
		}
	}, [clientState.loaded, getClients]);

	return (
		<Layout>
			<PageTitle title='Projects Dashboard' />
			{projectState.loaded && (
				<div className='flex w-full justify-between mt-20'>
					<CardStack
						stackTitle='Project'
						showAddNewProject={true}
						setShowAddProjectModal={setShowAddProjectModal}
						projects={projectState.projects.filter(
							(project) => project.projectStatus === "Quote"
						)}
					/>
					<CardStack
						stackTitle='Pending/In Process'
						showAddNewProject={false}
						setShowAddProjectModal={setShowAddProjectModal}
						projects={projectState.projects.filter(
							(project) => project.projectStatus === "Pending"
						)}
					/>
					<CardStack
						stackTitle='Completed'
						showAddNewProject={false}
						setShowAddProjectModal={setShowAddProjectModal}
						projects={projectState.projects.filter(
							(project) => project.projectStatus === "Completed"
						)}
					/>
				</div>
			)}
			{showAddProjectModal && (
				<ProjectModal setShowModal={setShowAddProjectModal} />
			)}
		</Layout>
	);
}
