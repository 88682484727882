import { useState } from "react";
import { firebaseAuth } from "../../../app/firebase";
import { getNotificationsApi } from "../api/get.notification";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setNotifications, updateNotifications } from "../store/notification.slice";
import { NotificationModel } from "../models/notification.model";
import { createNotificationApi } from "../api/post.notification";
import { markNotificationReadApi } from "../api/put.notification";

export default function useNotifications() {
	const [isLoading, setIsLoading] = useState(false);
	const [actionSuccessful, setActionSuccessful] = useState(false);

	const dispatch = useDispatch();

	const getNotifications = async () => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const notifications = await getNotificationsApi(idToken);
				dispatch(setNotifications(notifications));
				setActionSuccessful(true);
			} catch (error) {
				console.log(error);
				toast.error("Error retrieving notifications");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const createNotification = async (notification: NotificationModel) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				await createNotificationApi(notification, idToken);
				setActionSuccessful(true);
				toast.success("Notification sent");
			} catch (error) {
				console.log(error);
				toast.error("Error sending notification");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const markNotificationRead = async (notification: NotificationModel) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const updatedNotification = await markNotificationReadApi(notification, idToken);
				dispatch(updateNotifications(updatedNotification));
				setActionSuccessful(true);
			} catch (error) {
				console.log(error);
			} finally {
				setIsLoading(false);
			}
		});
	};

	return {
		isLoading,
		actionSuccessful,
		getNotifications,
		createNotification,
		markNotificationRead,
	};
}
