import { useState } from "react";
import { useDispatch } from "react-redux";
import { firebaseAuth } from "../../../app/firebase";
import { getClientsApi } from "../api/get.user";
import {
	setClients,
	updateClients,
	deleteClients,
} from "../store/client.slice";
import { toast } from "react-toastify";
import { createClientApi } from "../api/post.user";
import { ClientModel } from "../models/client.model";
import { deleteClientApi } from "../api/delete.user";
import { updateClientApi } from "../api/put.user";

export default function useClients() {
	const [isLoading, setIsLoading] = useState(false);
	const [actionSuccessful, setActionSuccessful] = useState(false);
	const dispatch = useDispatch();

	const getClients = async () => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const clients = await getClientsApi(idToken);
				dispatch(setClients(clients));
				setActionSuccessful(true);
			} catch (error) {
				console.log(error);
				toast.error("Error retrieving clients");
				dispatch(setClients([]));
			} finally {
				setIsLoading(false);
			}
		});
	};

	const createClient = async (client: ClientModel) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const updatedClient = await createClientApi(client, idToken);
				dispatch(updateClients(updatedClient));
				setActionSuccessful(true);
				toast.success("Client created");
			} catch (error) {
				console.log(error);
				toast.error("Error creating client");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const updateClient = async (client: ClientModel) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const updatedClient = await updateClientApi(client, idToken);
				dispatch(updateClients(updatedClient));
				setActionSuccessful(true);
			} catch (error) {
				console.log(error);
				toast.error("Error updating client");
			} finally {
				setIsLoading(false);
			}
		});
	};

	const deleteClient = async (client: ClientModel) => {
		setIsLoading(true);
		firebaseAuth.currentUser?.getIdToken(true).then(async (idToken) => {
			try {
				const deletedClient = await deleteClientApi(client, idToken);
				dispatch(deleteClients(deletedClient));
				setActionSuccessful(true);
				toast.success("Client deleted");
			} catch (error) {
				console.log(error);
				if (error.message === "DeleteClient/LinkedProject") {
					toast.warning(
						"Unable to delete client with linked projects. Please delete projects before deleting client."
					);
				} else {
					toast.error("Error deleting client");
				}
			} finally {
				setIsLoading(false);
			}
		});
	};

	const clientFromId = (clientId: string, clients: Array<ClientModel>) => {
		const client = clients.filter((client) => client.id === clientId)[0];
		return client;
	};

	return {
		isLoading,
		actionSuccessful,
		getClients,
		createClient,
		updateClient,
		deleteClient,
		clientFromId,
	};
}
